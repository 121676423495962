@import './theme/variables.scss';

.static-pages {
    h1 {
        font-weight: normal;
    }

    .header-container {
        padding: 10px;

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    .content-container {
        justify-content: space-evenly;
        display: flex;

        @media (max-width: 991px) {
            display: block;
        }

        .content-left {
            display: flex;
            justify-content: space-evenly;
            order: 1;
            max-width: 67%;
            padding: 5px;

            @media (max-width: 991px) {
                display: block;
                max-width: 100%;
                padding: 0;
            }

            .para-1 {
                width: 100%;
                max-width: 50%;
                padding-right: 5px;
                padding-left: 5px;

                @media (max-width: 991px) {
                    max-width: 100%;
                    padding: 0;
                }

                p {
                    &:last-of-type {
                        margin-bottom: 0; 

                        @media (max-width: 991px) {
                            margin-bottom: 1rem;
                        }
                    }
                }

                &.max {
                    flex: 1 1 100%;
                    max-width: none;
                }

                h5 {
                   font-size: 1rem;
                }
            }

            .para-2 {
                width: 100%;
                max-width: 50%;
                padding-right: 5px;
                padding-left: 5px;

                @media (max-width: 991px) {
                    display: block;
                    max-width: 100%;
                    padding: 0;
                }

                h5 {
                   font-size: 1rem;
                }
            }

            &.content-left-override {
                    flex:  1 1 100%;
                    max-width: none;


                    .btn-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 45px;

                        .btn {
                            max-width: 250px;
                        }
                    }

                    h4 {
                        font-size: 35px;
                        color: #0B406B;
                        text-align: center;
                    }
                }
        }

        .content-right {
            order: 2;
            width: 100%;
            padding: 5px;

            @media (max-width: 991px) {
                    display: block;
                    max-width: 100%;
                    padding: 0;
                }

            .img-wrapper {
                position: relative;

                .slogan-container {
                    position: absolute;
                    top: 12px;
                    right: 65px;
                    max-width: 200px;

                    @media (max-width: 991px) {
                        right: 265px;
                    }

                    @media (max-width: 767px) {
                        position: relative;
                        max-width: none !important;
                        top: 0;
                        right: 0;
                    }

                    span {
                        color: #000;

                        @media (max-width: 767px) {
                            font-size: 1rem;
                        }
                    }
                }
            }

            h3 {
                color: #000;
                font-size: 30px;
            }
        }

        .cta-paragraph {

            p {
                color: #0B406B;
                font-size: 20px;
                max-width: 40%;
                margin-bottom: 0;
                font-style: italic;

                @media (max-width: 991px) {
                    max-width: 100%;
                }
            }

            .btn-blue {
                width: 240px;
                margin: 0 auto;

                @media (max-width: 991px) {
                    margin: 1rem auto;
                }

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .btn {
            width: 100%;

            @media (max-width: 767px) {
                max-width: 100%;
                margin: 1rem auto;
            }

            &.contact-us {
                    width: 100%;

                    @media (max-width: 991px) {
                        max-width: 240px;
                        margin: 1rem auto;
                        display: block;
                    }
                }
        }

        form {
            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }
        }

        ol, ul {
            list-style-type: decimal;
            padding-left: 15px;
            margin-bottom: 3.3rem;

            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }

                li {
                    display: list-item;
                    line-height: 1.6rem;
                    font-size: 1rem;
                    color: #777;
                }
            }

    }

    section {
        padding-top: 25px;
        max-width: 1110px;
        margin: 0 auto;
    }

    .bg-gray {
        height: 100vh;
        max-height: 352px;
        background-color: #EEEEEE;
        margin: 1rem 0;

        @media (max-width: 991px) {
                max-height: none !important;
                height: auto;
                padding: 10px 0;
            }

        .quote {
            width: 100%;
            max-width: 53%;
            text-align: center;
            color: #0B406B;
            font-size: 20px;

            @media (max-width: 991px) {
                    max-width: 100%;   
                }


            .fa {
                margin-bottom: 1.3rem;
            }
            

            blockquote {
                font-style: italic;
                margin-bottom: 1.2rem;
            }
        }
    }

    p {
        color: #777;
        line-height: 1.6rem;
        font-size: 1rem;
        margin-bottom: .8rem;

        &:last-of-type {
            margin-bottom: 0;

            @media (max-width: 991px) {
                margin-bottom: 1rem;
            }
        }
    }

    .bg-light-blue {
        background-color: #A5B8C5;
        padding: 10px !important;
        margin: 1rem 0 0 0;

        @media (max-width: 991px) {
            margin: 1rem 0;
        }


        h5 {
            font-family: $myriadPro-Bold;
            margin-bottom: 25px;
        }

        p {
            margin-bottom: 1.4rem;
        }

        ul {

            li {
                display: block;
            }   
        }
    }

.heading-2 {
        margin: 25px 0;

        @media (max-width: 991px) {
            margin: 1rem 0;
        }
}


    img {
        margin-bottom: 1rem;

        @media (max-width: 991px) {
            margin: 1rem auto;
            display: block;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }
    }

    &.how-to-buy {
        .content-container {
            .content-right {
                .slogan-container {
                        position: absolute;
                        top: inherit !important;
                        right: inherit !important;
                        max-width: 220px !important;
                        bottom: 22px;
                        left: 22px;

                        @media (max-width: 991px) {
                            left: 182px;
                        }

                        @media (max-width: 480px) {
                            left: 8px;
                        }

                    span, .fa {
                        text-transform: uppercase;
                        font-size: 14px;
                        color: #fff !important;
                    }

                    a {
                        color: #fff;
                    }
                }

                .btn-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 45px;

                    .btn {
                        max-width: 250px;
                    }
                }

                a {
                    color: #000;
                }

                .justify-right-blue {
                    text-align: right;
                    color: #0B406B;
                    font-size: 20px;
                    font-style: italic;

                    @media (max-width: 768px) {
                        text-align: left;
                    }
                }

                &.content-right-override {
                   &.custom-width {
                        max-width: 67%;

                        @media (max-width: 991px) {
                            max-width: none;
                        }
                   } 
                }
            }   

            .content-left {

                .para-1 {
                    max-width: none;
                }

                ul {
                    margin-bottom: 1rem;

                    li {
                        line-height: 1.6rem;
                        font-size: 1rem;
                        margin-bottom: .5rem;
                    }
                }
            }

            .three-column {
                padding-right: 10px;
                padding-left: 10px;

                @media (max-width: 991px) {
                    padding-right: 0;
                    padding-left: 0;
                }

                hr {
                    width: 150px;
                    margin: 1.8rem 0 !important;
                }

                ul {
                    list-style-type: none;

                    li {
                        color: #777;
                        &:before {
                            content: '-';
                            display: inline-block;
                            margin-right: .5rem;
                        }
                    }
                }
            }
            
            &.line-items {
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    display: flex;

                    div {
                        display: flex;
                        padding: 15px 3px !important;
                        align-items: flex-start;
                        justify-content: center;

                        @media (max-width: 768px) {
                            justify-content: flex-start;
                        }

                        .fa {
                            margin-right: 7px;
                            font-size: 20px;
                        }
                    }
                }
        }

        h5 {
            text-transform: uppercase;
            font-family: $myriadPro-Bold;
        }

        .solution-container {
            align-items: center;
            background-color: #EEEEEE;
            padding: 25px;
            margin-top: 25px;

        }

        .bg-light-blue {
            ul {
                padding-left: 0;

                li {
                    display: flex;

                    i {
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    &.why-equify {
        .content-container {
            .content-left {
                &.max {
                    max-width: none
                }

                .para-1 {
                    &.max {
                        max-width: none;
                    }
                }

                .vertical-hr {
                    width: 3px;
                    margin: 0 35px;
                    height: 75%;
                    background-color: #A5B8C5;
                }

                &.two-tab-stack {
                    margin-bottom: 25px;
                }
            }

            p {
                strong {
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            .btn-wrapper {
                padding: 35px 0;
            }
        }
    }

    &.veritread {
        .square-container {
            position: relative;
            flex: 0 0 50%;
            max-width: 49%; 
            padding: 25px;
            margin: 5px;

            @media (max-width: 1200px) {
                max-width: 48%;
            }

            @media (max-width: 991px) {
                max-width: none;
                margin: 5px 0;
            }

            &.gray {
                background-color: #EEEEEE;
            }

            &.blue {
                background-color: #A5B8C5;
            }

            &.has-header {
                padding: 0;

                .para-container {
                    padding: 5px 25px;
                }
            }

            h4 {
                font-family: $myriadPro-Bold;
                text-transform: uppercase;
            }

            .header-bg-gray {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                background-color: #A5B8C5;
                padding: 8px 0;
                text-transform: uppercase;
                color: #000;
                font-weight: bold;
                text-align: center;
                font-family: $myriadPro-Bold;
            }
        }

        .content-right {
            .btn-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 35px;

                .btn {
                    max-width: 250px;
                }
            }
        }

        .img-wrapper {
            display: flex;
            position: relative;
            width: 100%;
            margin-top: 25px;
            padding-right: 5px;
            padding-left: 5px; 

            img {
                width: 100%;
            }

            button {
                position: absolute;
                max-width: 250px;
                right: 55px;

                @media (max-width: 576px) {
                    left: 50%;
                    top: 50%;
                    right: inherit;
                    transform: translate(-50%, -50%);
                }
            }


        }
    }

    h5 {
            font-family: $myriadPro-Bold;
            text-transform: uppercase;
            font-size: 1rem;
        }
}