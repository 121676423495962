﻿@import "./theme/_variables.scss";

.search {

    .form-control {
        position: relative;
        border-right: 1px solid rgb(217, 221, 233);
        background-color: rgba(228, 236, 240, .7);
        color: rgb(170, 177, 199);
        text-transform: uppercase;
    }

    .input-group-append {
        max-height: 45px;

        .btn {
            background-color: $secondary-color;
        }
    }

    .filter-selection-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 5px;
        
        .filter-choice {
            position: relative;
            border: solid 1px #d9dde9;
            margin: 2px 3px;

            span {
                position: relative;
                vertical-align: text-top;

                .fa {
                    margin-left: 4px;
                }
            }
        }
    }

    .filterNav {
        position: relative;
        left: 0;
        border-right: 1px solid $bg-color2;
        border-top: 1px solid $bg-color2;
        border-left: 1px solid $bg-color2;
        // border: 1px solid $bg-color2;
        // overflow-y: scroll;
        display: inline-block;
        float: left;
        z-index: 2;
        height: auto;

        @media (max-width: 767px) {
            left: -9999px;
            position: fixed;
            transition: all 0.3s ease;
            width: auto;
            height: 100vh;
            z-index: 9999;
            overflow: inherit;
        }

        .sidebar-back {
            padding: 20px;
            font-size: inherit;
            margin-bottom: 0;
            display: block;
            border-bottom: 1px solid $bg-color2;
            display: flex;
            justify-content: space-between;

            span {
                cursor: pointer;

                .badge {
                    color: $white;
                }
            }

            .mobile-back-container {
                display: flex;
                align-items: baseline;

                span {
                    font-size: 18px;
                    color: #222222;
                    font-weight: 700;
                }
            }
        }

        .uncontrolled-collapse {
            &.searchNav {
                border-top: 0;
                border-bottom: solid 1px $bg-color2;
            }

            h3 {
                font-family: $myriadReg;
                text-transform: uppercase;
                font-size: 1rem;
            }
        }

        .show-more {
            top: 5px;
            position: relative;

            &:hover {
                opacity: .8;
            }
        }

        .dropdown-header {
            border-bottom: solid 1px $bg-color2;
            padding: .8rem 1rem;
            text-transform: uppercase;
            position: relative;

            .form-check-input {
                margin: 0;
                right: 15px;
            }

            .menu-content {
                font-weight: bold;
                margin-top: 15px;
                margin-left: 6px;
                white-space: normal;
                color: $black;
                padding: 7.5px 0;

                .toggle-wrapper {
                    min-height: 37px;
                    max-width: 190px;
                }
            }

            .form-control {
                height: inherit;
            }

            .react-toggle {
                float: right;
                position: absolute;
                right: 10px;
            }

            .inline-toggle-label {
                margin: 0;
                padding: 0;
                flex: 1;
                word-break: break-word;
            }
        }
    

        &.open-sidebar {
            left: -300px;
            position: absolute;
            transition: all 0.3s ease;
            width: auto;

            @media (max-width: 767px) {
                position: fixed;
                left: 0;
                z-index: 999;
                height: 100vh;
                width: 85%;
                border: 0;
                // overflow: scroll;
            }

            .sidebar-overlay {
                visibility: visible;
                opacity: 0.8;
                overflow: hidden;
            }

            nav {
                overflow-y: auto;
            }

            #sub-menu {
                @media (max-width: 767px) {
                    padding-bottom: 25px;
                }
            }
        }

        .mobile-search-container {
            border-bottom: 1px solid $bg-color2;
            padding-bottom: 15px;
        }

        .collapse {
            // max-height: 100vh;
            // overflow: auto;
        }

        .sidebar-overlay {
            visibility: hidden;
            opacity: 0;
            background-color: #212331;
            position: fixed;
            z-index: 6;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-transition: all 1s;
            transition: all 1s;
        }

        &.sidenav {
            overflow: inherit;

            nav {
                height: auto;

                @media (max-width: 767px) {
                    height: 100vh;
                }
            }
        }
    }

    .search-results-container {
        width: calc(100% - 300px);
        display: inline-flex;
        float: left;
        position: relative;

        @media (max-width: 767px) {
            width: 100%;
        }

        .img-wrapper {
            position: relative;

            .btn {
                position: absolute;
                top: 5px;
                right: 5px;
                border-radius: 3px;
                background-color: #ffffff;
                border: solid 1px #bfbfbf;
                max-height: 36px;
                z-index: 9999;
            }
        }
    }

    .slide-container-hidden {
        position: relative;
        left: 0;
        top: 6px;
        float: left;
        width: 60px;
        z-index: 1;
        display: none;

        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 15px;
            position: relative;
            max-height: auto;
            border-right: 0;
            display: block;

            .mobile-filter {
                text-align: center;

                &.border-right {
                    border-right: solid 1px $light-grey-border;
                }

                .fa {
                    font-size: 20px;
                }

                span {
                    vertical-align: baseline;
                }
            }
        }

        .fa {
            font-size: 30px;
            cursor: pointer;
            z-index: 2;
            width: 100%;
            text-align: center;
            padding-bottom: 10px;
            padding-top: 10px;

            @media (max-width: 767px) {
                width: auto;
            }
        }
    }

    .floating-cta {
        position: absolute;
        top: -17px;
        left: 50%;
        background-color: $white;
        color: $primary-color;
        width: 100%;
        max-width: 180px;
        box-shadow: 0 0 1px rgba(0,0,0,.7);
        border: solid 1px $bg-color2;
        transform: translateX(-50%);
        padding: 4px;
        border-radius: 12px;
        font-size: .8rem;
        font-weight: 600;
        text-align: center;
    }

    .slick-slider {
        max-height: 54px;

        .slick-slide {

            .btn {
                margin: 10px 5px;
                width: -webkit-fill-available;
            }
        }
    }

    .container {
        &.fluid {
            width: 100%;
            max-width: none;
            display: flex;
            padding: 0;

            @media (min-width: 1440px) {
                max-width: 1440px;
            }
        }

        .row {
            @media (max-width: 576px) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .buyingContainer {
        &.saleDescription {
            border-top: solid 1px $bg-color2;
            text-align: center;
            display: flex;
            justify-content: center;            

            h4 {
                margin-bottom: 0;
            }

            &:hover {
                background-color: $secondary-color;

                h4 {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }

    .result-container {
        justify-content: space-around;

        h5 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .filter-button-container {
            display: flex;
            justify-content: flex-end;

            @media (max-width: 576px) {
                justify-content: flex-start;
                margin-top: 15px;

                .btn, .btn-group {
                    width: 100%;               }
            }

            .btn {
                max-height: 45px;
                padding: 8px;
                align-items: center;
                display: flex;

                span {
                    margin-left: 5px;
                }
            }
        }
    }
}

.create-alert-ul {
    li {
        border: solid 1px $light-grey-border;
        padding: 5px 15px;
        margin-top: 5px;
        text-transform: uppercase;
        display: block;

        .fa {
            float: right;
            margin: 3px;
        }
    }
}

.not-found {
    width: 100%;
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 576px) {
        max-width: 300px;
    }
}

.inventory-cta {
    background-color: $secondary-color;
        
    .fa {
        font-size: 4rem;
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:  translate(-50%, -50%);
    }

    .btn {
        &:hover {
            color: rgba(0,0,0,.8) !important;
            background-color: $white;
            opacity: 1;
        }
    }
}

.search-img-border {
    border-top: solid 1px $bg-color2;
    border-left: solid 1px $bg-color2;
    border-right: solid 1px $bg-color2;
    max-height: 185px;
    min-height: 185px;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.keyword-search-wrapper {
    @media (min-width: 1440px) {
        padding-left: 0;
    }
}