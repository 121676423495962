﻿@import '../scss/theme/_variables.scss';
@import "~spinthatshit/src/loaders";

@for $value from 0 through 100 {
    .padding-#{$value} {
        padding: #{$value}px !important;
    }

    .padding-left-#{$value} {
        padding-left: #{$value}px !important;
    }

    .padding-right-#{$value} {
        padding-right: #{$value}px !important;
    }

    .padding-top-#{$value} {
        padding-top: #{$value}px !important;
    }

    .padding-bottom-#{$value} {
        padding-bottom: #{$value}px !important;
    }
}

@for $value from 0 through 100 {
    .margin-#{$value} {
        margin: #{$value}px !important;
    }

    .margin-left-#{$value} {
        margin-left: #{$value}px !important;
    }

    .margin-right-#{$value} {
        margin-right: #{$value}px !important;
    }

    .margin-top-#{$value} {
        margin-top: #{$value}px !important;
    }

    .margin-bottom-#{$value} {
        margin-bottom: #{$value}px !important;
    }
}

@for $value from 0 through 150 {
    .font-size-#{$value} {
        font-size: #{$value}px !important;
    }
}

@for $value from 0 through 50 {
    .border-radius-#{$value} {
        border-radius: #{$value}px !important;
    }
}

.border-top {
    border-top: 1px solid $light-grey-border !important;
}

p {
    color: $text-color1;
    font-size: 1rem;
    word-break: break-word;
}

.spinThatShit-loader {
    @include loader07($size: 20px, $duration: 1s, $align: middle, $color: $secondary-color);
    margin: 30px auto 35px auto;
}

.loader-spin-3-white {
    @include loader07($color: white, $size: 3px);
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 3px;
  }

  .loader-12 {
      @include loader12($size: 10px, $duration: .5s, $align: middle, $color: $secondary-color);
  }

.center-block {
    margin: 0 auto;
    display: block;
}

.valid-feedback, .invalid-feedback {
    font-size: .8rem;
}

.error-text {
    color: red;
    font-size: .8rem;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 5px;
}

.card {

    .card-header {
        background-color: $white;

        h3 {
            margin-bottom: 0;
            color: $black;
        }
    }

    &.input-card {
        border-radius: 0;
        border: 0;
        
        .card-header {
            padding: 0.05rem;
            font-weight: bold;
            color: $black;
            border-bottom: 0;
            font-family: $bebasNeue;
            font-size: 1.5rem;
            margin-bottom: 5px;
        }

        .card-body {
            padding: 0;

            ul {
                li {
                    display: block;
                    padding: 13px 10px;
                    color: $black;
                    border: solid 1px $light-grey-border;
                    margin-bottom: 10px;
                    font-size: 1.1rem;

                    &.active {
                        color: $secondary-color;
                        background-color: $light-grey;
                    }
                }
            }
        }

        .form-control {
            margin-bottom: 1rem;

            &.is-invalid {
                margin-bottom: 0;
            }
        }

        &.form {
            .card-header {
                font-size: 1.4rem;
                font-family: $bebasNeue;
                text-transform: uppercase;
            }
        }
    }
}

.form-check {
    .form-check-input {
        position: relative;
        margin: 7px 0;
        width: 30px;
        height: 30px;
        border-radius: 0;

        @media (max-width: 1024px) {
            height: 20px;
            width: 20px;
        }
    }

    label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 5px;
    }

}

.font-blue {
    color: $secondary-color !important;
}

.font-black {
    color: #000 !important;
}

.bebas {
    font-family: $bebasNeue;
}

.font-dark {
    color: $primary-color;
}

.underline {
    &:hover {
        text-decoration: underline !important;
    }
}

.link-underline {
        cursor: pointer;
        text-decoration: underline !important;
}

.global-loader {
    display: flex;
    justify-content: center;
    margin: 15px 15px;
}

.loader-spin {
    @include loader01($color: $primary-color);
  }

  .loader-spin-10 {
    @include loader10($color: $primary-color, $size: 20px, $gap: 8px, $duration: 1s, $align: middle);
  }

  .loader-spin-12 {
    @include loader12($color: $primary-color, $size: 10px, $gap: 20px);
    padding: 5px 0 5px 0;
  }

  .small-loader-7-white {
    @include loader07($size: 4px, $duration: .5s, $align: middle, $color: $white);
 }

 .small-loader-7-dark {
    @include loader07($size: 4px, $duration: .5s, $align: left, $color: $primary-color);
 }



  .vertical-align {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  }

  .vertical-hr {
    background-color: $bg-color2;
    width: 1px;
    display: inline-block;
  }

  .auction-fade {
      pointer-events: none;
      opacity: .5;
  }

  .custom-next-arrow, .custom-prev-arrow {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background: rgba(0,0,0,.5);
    padding: 0;
    border: none;
    outline: none;

    &:hover {
        background-color: rgba(0,0,0,.2);
    }
  }

  .custom-next-arrow {
      right: 0;
      z-index: 99;

      &:before {
        content: '\f105';
        font-family: "FontAwesome";
        color: #fff;
        opacity: 1;
        font-size: 2.5rem;
        vertical-align: bottom;
      }
  }

  .custom-prev-arrow {
      left: 0;
      z-index: 99;

    &:before {
        content: '\f104';
        font-family: 'FontAwesome';
        color: #fff;
        opacity: 1;
        font-size: 2.5rem;
        vertical-align: bottom;
      }
  }

  #chat-widget-container {
      @media (max-width: 576px) {
          display: none;
      }
  }



  .no-events {
      color: rgba(0,0,0,.5) !important;

      &:hover {
          cursor: not-allowed;
      }

      &.font-white {
          color: #fff !important;
      }
  }

  .form-group-container {
    .form-group {
        margin-bottom: 1.1rem;
        .react-toggle {
            margin-bottom: 15px;
        }

        .error-text {
            margin-top: -0.1rem;
            margin-bottom: auto;
        }
    }

    .input-group {
        margin-bottom: 1.1rem;
    }

    .error-text {
        margin-top: -1.1rem;
        // margin-bottom: 1.3rem;
    }

    .no-margin {
        .error-text {
            margin-bottom: 0;
        }
    }
}

.myriadRegBold {
    font-family: $myriadPro-Bold;
}

.myriadReg {
    font-family: $myriadReg;
}

.justify {
    text-align: justify;
}
.secondary-color{
    color:$secondary-color;
}

.se-table-size-100 {
  width: 100%;
}

.global-primary-bg {
    background: $primary-color !important;
    div {
        color: #fff !important;
    }
}

.default-cursor * {
    cursor: auto !important;
}