
@import "../../../public/assets/scss/slick.scss";
@import "../../../public/assets/scss/slick-theme.scss";
@import "../../../public/assets/scss/color1.scss";
@import "../../../public/assets/scss/overrides.scss";
@import "../../../public/assets/scss/layout.scss";
@import "../../../public/assets/scss/theme/_variables.scss";
@import "../../../public/assets/scss/tabs.scss";
@import "../../../public/assets/scss/globals.scss";
@import "../../../public/assets/scss/featured.scss";
@import "../../../public/assets/scss/search.scss";
@import "../../../public/assets/scss/auction-item.scss";
@import "../../../public/assets/scss/gallery.scss";
@import "../../../public/assets/scss/liveauction.scss";
@import "../../../public/assets/scss/home.scss";
@import "../../../public/assets/scss/cart.scss";
@import "../../../public/assets/scss/welcome.scss";
@import "../../../public/assets/scss/cart.scss";
@import "../../../public/assets/scss/purchase.scss";
@import "../../../public/assets/scss/account.scss";
@import "../../../public/assets/scss/register.scss";
@import "../../../public/assets/scss/notifications.scss";
@import "../../../public/assets/scss/compare.scss";
@import "../../../public/assets/scss/responsive-table.scss";
@import "../../../public/assets/scss/browser-detect.scss";
@import "../../../public/assets/scss/static-pages.scss";
@import "../../../public/assets/scss/online-events.scss";

.home {
    position: relative;

    .slider-contain {
        position: absolute;
        top: 40%;
        left: 50%;
        display: block;

        @media (max-width: 600px) {
            position: relative;
            top: 0;
            left: 0;
        }

        h2 {
            color: $white;
            text-shadow: 1px 1px rgba(0,0,0,.5);
            text-align: left;
        }

        .btn-solid {
            float: left;
        }

        .simplifty-container {
            background-color: rgba(0, 0, 0, 0.7);
            height: 220px;
            padding: 35px;
            width: 390px;

            @media (max-width: 600px) {
                height: 300px;
                width: 100%;
                padding: 16px;
            }

            .btn {
                @media (max-width: 600px) {
                    margin-bottom: 5px;
                }
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.header-dropdown {
    @media (max-width: 1388px) {
        margin-left: 0;
    }

    .onhover-dropdown {
        &:before {
            right: -15px;
        }
    }

    .navbar-input-group {
        max-height: 30px;

        .form-control {
            height: 30px;

            &:focus {
                border-color: $white; 
                box-shadow: none;
                border: solid 1px $light-grey-border;
            }
        }

        .input-group-append {
            height: 30px;

            .input-group-text {
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: $white;
            }
        }
    }
}

.live-auction-banner {
    background-color: $primary-color;
    position: absolute;
    top: 0;
    padding: 15px;
    z-index: 1;
    width: 100%;

    h2 {
        margin-right: 10px;
        color: $white;
    }

    p, span, h3 {
        color: $white;
    }

    h3 {
        margin-bottom: 0;
    }
}

.title-inner1 {
    &:after {
        background-color: $primary-color !important;
    }
}

.upcomming {
    margin-top: 25px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.onhover-dropdown {
    .onhover-show-div {
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);

        li {
            a {
                &:hover {
                    color: $primary-color !important;
                }
            }
        }
    }
}

.live-auction-button {
    background-color: #b22329;
    -webkit-animation: flash linear 2s infinite;
    animation: flash linear 2s infinite;
    max-width: 60px;
    text-align: center;
    font-weight: bolder;
    font-size: 20px;
    color: #fff;
    padding: 1px 5px;
    max-height: 31px;
    text-transform: uppercase;

    @-webkit-keyframes flash {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .1;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes flash {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .1;
        }

        100% {
            opacity: 1;
        }
    }
}
