﻿@import 'theme/_variables.scss';

.search-offset-top {
    position: relative;
    top: -50px;

    @media (max-width: 480px) {
        top: -20px;
    }

    .nav {
        &.nav-tabs {
            border: 0;

            .nav-item {
                border-top-left-radius: 0.25rem;
                border-top-right-radius: 0.25rem;
                color: $white;
                max-height: fit-content;

                .nav-link {
                    text-transform: uppercase;
                    padding: 15px;
                    background-color: $primary-color;

                    &.active {
                        background-color: $white;
                        border-color: $white;
                        color: black;
                    }

                    &:hover {
                        border-color: transparent;
                    }
                }
            }
        }
    }

    .tab-content {
        background-color: $white;
        box-shadow: rgba(24, 53, 88, 0.1) 0px 14px 21px 0px;
        padding: 20px 13px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        transition: all 0.5s ease-in-out 0s;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        .form-group {
            .form-control {
                padding: 0.45rem 0.75rem;
            }
        }

        .padding-left-0 {
            @media (max-width: 575px) {
                padding-left: 15px !important;
                margin-top: 10px;
            }
        }
    }
}
