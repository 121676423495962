
%baseRow {
    color: #005DA8;
    letter-spacing: 0.5px;
    line-height: 1.2;
    border-bottom: 2px solid #dee2e6;
    padding: 10px;
    margin:10px;
    align-content: center;
    align-items: center;

    label{
        color:#777777;
    }
    
    a {
        color:gray;
        font-size: 16px;
    }
}

.cart-section {

    .cart-row {
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: solid 1px $light-grey-border;
        border-right: solid 1px $light-grey-border;
        border-left: solid 1px $light-grey-border;
        margin-left: 0;
        margin-right: 0;

        &:last-child {
            border-bottom: solid 1px $light-grey-border;
        }

        .form-check {
            padding-left: 0;

            .form-check-input {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;

                @media (max-width: 1024px) {
                    right: 10px;
                }

                @media (max-width: 768px) {
                    right: 15px;
                }
              }
        }

        p {
            color: rgba(0,0,0,.6);

            span {
                color: $black;
                margin-left: 10px;

                @media (max-width: 1024px) {
                    margin-left: 0;
                }
            }
        }

        img {
            max-height: 160px;

            @media (max-width: 768px) {
                width: 100%;
                max-height: none;
                margin-bottom: 15px;
            }
        }
    }
}

.summary-container {
    background-color: $primary-color;
    padding: 15px;

    @media (max-width: 768px) {
        margin-top: 20px;
    }
    
    h3 {
        width: 100%;
        color: $white;

        span {
            font-size: 1rem;
        }
    }

    .border-bottom {
        width: 100%;
        opacity: .5;
    }

    p {
        margin-top: 15px;
        color: rgba(255,255,255,.8);

        span {
            float: right;
            color: $white;
            opacity: 1 !important;
        }

        &.total {
            font-size: 1.1rem;

            span {
                font-size: 1.3rem;
            }
        }
    }
}

.special-financing {
    position: relative;
    padding: 10px;
    background-color: $light-grey;
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 15px;

    p {
        display: inline-block;
        color: rgba(0,0,0,.6);
        font-family: $bebasNeue;
        font-size: inherit;
        margin-bottom: 0;
        vertical-align: middle;
    }

    a {
        display: inline-block;
        color: $secondary-color;
        vertical-align: middle;
        font-family: $bebasNeue;
        margin-left: 7.5px;
    }

    .fa {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
    }
}
