@import 'theme/_variables.scss';

.verify-block {
    position: relative;

    .input-group-append {
        .btn-block {
            margin-top: 0;
        }
    }

    .fa {
        font-size: 2.2rem;
        color: green;
    }
}

.register-page {
    margin-top: 25px;

    @media (max-width: 576px) {
        margin-top: 0;
    }

    @media (min-width: 1200px) {
        max-width: 1200px;
    }

  h1 {
      margin-top: 20px;

      span {
          font-size: 1.1rem;
          float: right;
          color: $black;
      }
  }

  .next-btn {
      background-color: $secondary-color;
  }

    .form-group {
        margin-bottom: 1.1rem;
        .react-toggle {
            margin-bottom: 15px;
        }

        .error-text {
            margin-top: -0.1rem;
            margin-bottom: auto;
        }
    }

    .input-group {
        margin-bottom: 1.1rem;
    }

    .error-text {
        margin-top: -1.1rem;
        // margin-bottom: 1.3rem;
    }

    .no-margin {
        .error-text {
            margin-bottom: 0;
        }
    }

    .register-page-container {
        display: flex;
        position: relative;

        div {
            order: 1;

            @media (max-width: 576px) {
                order: 2;
            }
        }

        .register-cta {
            order: 2;
            background-color: $light-grey;
            height: 100vh;
            min-height: 450px;
        
            @media (min-width: 1600px) {
                max-width: 440px;
            }
        
            @media (max-width: 768px) {
                height: auto;
                padding-bottom: 25px;
                padding-top: 25px !important;
                margin-top: 25px;
            }

            @media (max-width: 576px) {
                order: 1;
            }
        
            h3 {
                font-size: 23px;
                text-align: center;
            }
        
            .slogan {
                font-family: $myriadPro-Bold;
                color: #222222;
                font-weight: 700;
                font-size: 1.1rem;
                text-align: center;
                width: 100%;
                margin-top: 25px;
            }
        
            p {
                font-size: 1.1rem;
                line-height: 1.75;
            }
        }
    }

    .btn {
        @media (max-width: 576px) {
            max-width: 100% !important;
        }
    }
}

.icon-wrapper {
    position: relative;
    width: 150px;
    height: 120px;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 25px;

    .fa {
        font-size: 6rem;
        top: 50%;
        left: 50%;
        position: relative;
        transform: translate(-50%, -40%);
    }
}

.verification-container {
    input {
        height: 45px;
        width: 100%;
        font-size:25px;
        text-align: center;
    }

    .col-2 {
        @media (max-width: 576px) {
            padding-right: 2px;
            padding-left: 2px;
        }
    }
}

.busn-details {
    &.address {
        .form-check-input {
            margin-left: .75rem;
            margin-top: .7rem;
        }

        .inline-check-label {
            font-size: .8rem;
            font-family: $myriadReg;
        } 
    }
    
    .form-check-input {
        margin-left: .75rem;
    }

    .react-toggle {

        &:hover {
            
            .react-toggle-track {
                opacity: .8;
                background-color: #005DA8;
            }
        }

        .react-toggle-track {
            background-color: #005DA8;
        }
    }
}

.form-check {
    &.toggle {
        .react-toggle {
            float: none;
            vertical-align: bottom;
        }
    }
}

.inline-toggle-label {
    padding: 3px 5px 0 5px;
    margin-top: 0px;
}

.progressbar {
    counter-reset: step;
    margin-left: -15px;
    width: 100%;

    @media (max-width: 768px) {
        margin-left: 0;
    }
}

.progressbar li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: 14px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;

    @media (max-width: 768px) {
        width: 33%;
        font-size: 12px;
    }

    &:hover {
        cursor: pointer;
    }
}
.progressbar li:before {
    width: 40px;
    height: 40px;
    content: counter(step);
    counter-increment: step;
    line-height: 40px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 20px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: #005DA8;
}
.progressbar li.active:before {
    border-color: #005DA8;
    background-color: #005DA8;
    color:#FFFFFF;
}
.progressbar li.active + li:after {
    background-color: #005DA8;
}

.success-container {
    display: flex;
    align-content: center;
    margin-top: 35px;

    h1 {
        text-align: center;
    }

    i {
        font-size: 4.7rem;
        text-align: center;
        display: block;
    }

    p {
        color: $primary-color;
        font-size: 1.1rem;
        text-align: center;
        line-height: 1.5;
        margin-bottom: .5rem;
    }
}
