@import  '../scss/theme/_variables.scss';

.user-account {
    .button-wrapper {
        position: relative;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 1200px) {
            margin-bottom: 25px;
        }

        .btn {
            &.active {
                border-color: $secondary-color;
            }
        }

        @media (max-width: 1024px) {
            margin-bottom: 35px;
        }
    }

    .verification-icon {
        position: relative;
        i {
            font-size: 2.25rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .padding-right-0 {
        @media (max-width: 576px) {
            padding: 0px !important;
        }
    }

    .inline-check-label {
        position: relative;
        top: inherit;
    }

    .card-header{
        .inline-toggle-label {
            padding: 3px 5px 0px 5px;
            vertical-align: top;
            font-size: .6em;
            text-transform: none;
        }
    }

    &.container {
        @media (min-width: 768px) {
            max-width: 960px;
        }

        @media (min-width: 1200px) {
            max-width: 1400px;
        }
    }

    .form-group {
        margin-bottom: 1.1rem;
        .react-toggle {
            margin-bottom: 15px;
        }

        .error-text {
            margin-top: 0rem;
            margin-bottom: auto;
        }
    }

    .input-group {
        margin-bottom: 1.1rem;

        .form-control {
            &:disabled {
                color: hsl(0,0%,60%);
            }
        }
    }

    .error-text {
        margin-top: -1rem;
        // margin-bottom: 1.3rem;
    }

    .no-margin {
        .error-text {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    h3 {
        padding: 0.05rem;
        font-weight: bold;
        color: #000000;
        border-bottom: 0;
        font-family: BebasNeue;
        font-size: 1.5rem;
        margin-bottom: 5px;
    }

    .alert {
        width: 100%;

        @media (max-width: 991px) {
            max-width: 100% !important;
        }
    }
}

.input-card {

    li {
        &:hover {
            cursor: pointer;
            color: $primary-color !important;
        }

        &.active {
            color: $primary-color;
            font-weight: bold;
        }

        @media (max-width: 991px) {
            width: 100%;
            max-width: 49.5%;
            display: inline-flex !important;

            &:nth-of-type(even) {
                margin-left: .5%;
            }
        }

        @media (max-width: 576px) {
            max-width: none;

            &:nth-of-type(even) {
                margin-left: 0; 
            }
        }
    }

    &.account-card {
        border: solid 1px $light-grey-border;

        .card-header {
            border-bottom: 0;
            padding: 10px 15px;

            &.header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &.btn {
                    margin-bottom: 0;
                }

                @media (max-width: 576px) {
                    display: block;

                    .btn {
                        max-width: 100% !important;
                    }
                }
            }
        }

        .card-body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            
            .table {
                thead th {
                    border-bottom: 1px solid $light-grey-border;
                    font-family: $bebasNeue;
                    color: $grey;
                }

               tbody tr {
                    &:nth-child(odd) {
                        background-color: $light-grey;
                    }
                }
            }
        }

        &.offer {
            .table {
                margin-bottom: 1.4rem;

                tbody {
                    .loader-spin-10 {
                        position: absolute;
                        left: 50%;
                        margin: -12px auto 0;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        top: auto;
                    }
                }
            }
        }
    }
}

.empty-wishlist-container {
    position: relative;
    height: 50vh;

    .empty-cart-cls {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.linkButton {
    color:#2B3C4A;
}

.account-item-wrapper {
    padding-top: 42px;

    @media (max-width: 767px) {
        padding-top: 10px;
    }

    &.help-center {
        padding-top: inherit;

        @media (max-width: 576px) {
            padding-top: 20px;
        }
        
        p {
            font-size: 1.1rem;
            line-height: 24px;
        }

        h5 {
            font-size: 1.5rem;
            font-weight: bolder;

        }
    }

    .mb-3 {
        @media (max-width: 576px) {
            margin-bottom: 0 !important;
            margin-top: 0.5rem;
        }
    }
}

.good{
    color:#28a745;
    font-weight: bold;
}

.boldLinkButton{
    color:#2B3C4A;
    font-weight: bold;
}

.bad{
    color:#dc3545;
    font-weight: bold;
}

.highlight{
    color:#005DA8;
    font-weight: bold;
}

.invoice-modal {
    p {
        margin-bottom: 1px;
    }
}

.credit-card-input{
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color:#495057 !important;
}

.wiring {
    li {
        display: block;
    }
}

.tt-table {
  th, td {
    padding: 0.5rem;
  }
}