﻿@import "theme/_variables.scss";

body {
    font-family: $myriadReg;
    letter-spacing: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $bebasNeue;
}

h1 {
    font-size: 40px;
}


.btn {
    font-family: $myriadReg;
    line-height: 24px;
    min-height: 40px;
    
    &.btn-solid {
        background-color: $secondary-color;
        background-image: none;
        border-color: $secondary-color;
        font-weight: bold;

        &.padding {
            padding: 0.375rem 0.75rem;
            width: 100%;
        }

        &:hover {
            background-color: $secondary-color;
            color: $white !important;
            opacity: .8;
        }

        &:active {
            background-color: $primary-color;
        }
    }

    &.btn-block {
        background-color: $primary-color;
        background-image: none;
        border-color: $primary-color;
        font-weight: bold;
        color: $white;

        &:hover {
            background-color: $primary-color;
            color: $white !important;
            opacity: .8;
        }

        &:active {
            background-color: $primary-color;
        }

        &:focus {
            box-shadow: none !important;
        }

        + .btn-block {
            margin-top: 0;
        }
    }

    &.btn-white {
        background-color: $white;
        background-image: none;
        border-color: $white;
        font-weight: bold;
        color: $primary-color !important;
        padding: 5px;

        &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white !important;
        }

        &:active {
            background-color: $primary-color;
        }
    }

    &.btn-white-on-white {
        background-color: $white;
        background-image: none;
        border-color: $primary;
        font-weight: bold;
        font-size: 0.85rem;
        color: $primary !important;
        padding: 10px;

        &:hover {
            background-color: $primary;
            border-color: $primary;
            color: $white !important;
        }

        &:active {
            background-color: $primary;
        }
    }

    &.btn-blue {
        background-color: $secondary-color;
        background-image: none;
        border-color: $secondary-color;
        font-weight: bold;
        font-size: 0.85rem;
        color: $white !important;
        padding: 10px;

        &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white !important;
            opacity: .8;
        }

        &:active {
            background-color: $secondary-color;
        }
    }

    &.btn-grid {
        background-image: none;
        border-color: $secondary-color;
        font-weight: bold;
        font-size: 0.75rem;
        color: $secondary-color !important;
        padding: 5px 10px;

        &:hover {
            background-color: $secondary-color;
            border-color: $secondary-color;
            color: $white !important;
            opacity: .8;
        }
    }

    @for $value from 0 through 100 {
        &.radius-#{$value} {
            border-radius: #{$value}px !important;
        }
    
        &.radius-top-left-#{$value} {
            border-top-left-radius: #{$value}px !important;
        }
    
        &.radius-bottom-left-#{$value} {
            border-bottom-left-radius: #{$value}px !important;
        }
    
        &.radius-top-right-#{$value} {
            border-top-right-radius: #{$value}px !important;
        }
    
        &.radius-bottom-right-#{$value} {
            border-bottom-right-radius: #{$value}px !important;
        }
    }
}

.instagram {
    padding-top: 50px;
    
    .instagram-box {
        background-color: $primary-color;
    
        img {
            min-height: 226px;
            object-fit: fill;
            max-height: 226px;
        }
    
        .overlay {
            &:hover {
                background-color: #003c71;
                opacity: .5;
            }
        }
    }
}

.section-b-space {
    padding-top: 50px;
    flex: 1;

    .footer-theme {
        .sub-title {
            li {

                a {
                    color: $white;

                    &:before {
                        background-color: $white !important;
                    }
                }
            }

            .contact-list {
                li {
                    color: $white;

                    &:hover {
                        color: $white;
                        opacity: .8;
                    }
                }

                a {
                    &:hover {
                        color: $white;
                    }
                }
            }

            .footer-title {
                h4 {
                    color: $white;
                    font-size: 1.5rem;
                }
            }
        }

        p {
            color: $white;
        }

        .footer-social {
            display: flex;
            justify-content: flex-start;

            a {
                padding: 0 8px;

                &:first-of-type {
                    padding-left: 0;
                }

                i {
                    color: $white;
                    &:hover {
                        color: $white !important;
                    }
                }
            }
        }
    }
}

.tap-top {
    background: $secondary-color;
     i {
         color: $white;
     }

     &:hover {
         opacity: .9;
     }
}

.subscribe {
    h4 {
        color: $white;
    }

    p {
        color: $grey-light;
    }
}

.subscribe-form {
    .btn-solid {
        background-color: $secondary-color;
        color: $white !important;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .input-group {
        flex: 0 0 100%;
    }

    .form-group {
        @media (max-width: 576px) {
            width: 100% !important;
            max-width: 100%;
        }

        .form-control {
            background-clip: border-box;
            
            @media (max-width: 576px) {
                width: 100% !important;
                max-width: 100%;
            }
        }
    }
}

.sub-footer {
    background-color: $primary-color;
    border-top: solid 1px $round-border;
    
    p {
        color: $white;
    }
}

.toggle-nav {
    padding: 0 !important;

    i {
        color: $header-menu-text-color !important;
        font-size: 28px;
    }
} 

.loader {
    background-color: $primary-color !important;
}

.h5-inverse {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding-top:10px;
}

.liveIcon {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: red;
    margin-right: 0.5vw;
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
}

.liveContainer {
    display: flex;
    border: 0.5px solid $grey-light;
    align-items: center;
    border-radius: 4px;
    padding: 0 0.5vw;
    margin-left: 1vw;

    @media (max-width: 1200px) {
        margin: 0;
    }
}

.live-btn-wrapper {
    .btn-white {
        margin: 10px 0;
       
        @media (max-width: 1200px) {
            background-color: $white;
            padding: 0 !important;
        }
    }
}

.liveText {
    color: #FFFFFF;
    padding: 5px;
}

.logo15 {
    vertical-align:middle;
    border-style:none;
    max-width:20%;

    @media (max-width: 767px) {
        max-width: 50%;
    }

    @media (max-width: 576px) {
        max-width: 75%;
    }
}

.breadcrumb-section {
    padding: 5px 0;
    border-bottom: solid 1px $bg-color2;
    border-top: solid 1px $bg-color2;
    margin-top: 108px;

    @media (max-width: 1400px) {
        margin-top: 125px;
    }

    @media (max-width: 1200px) {
        margin-top: 67px;
    }

    @media (max-width: 576px) {
        margin-top: 67px;
    }

    .breadcrumb {
        padding: .25rem 1rem;
        justify-content: flex-start;

        .breadcrumb-item {
            color: #6c757d;

            a {
                color: #6c757d;
            }

            &.active {
                font-weight: bold;
                color: #2B3C4A;
            }
        }
    }

    .page-title {

        h2 {
            font-family: $myriadPro-Bold;
        }
        
    }
}

.slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    color: $secondary-color;
    border: solid 1px $light-grey-border;
    background-color: #fff;
    z-index: 5;
}

 .slick-next:before {
    content: '\f061';
    font-family: $font-awesome;
    color: $secondary-color;
    opacity: 1;
}

.slick-prev:before {
    content: '\f060';
    font-family: $font-awesome;
    color: $secondary-color;
    opacity: 1;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: $secondary-color;
    background-color: #fff;
}

.slick-slider {
    
    &.d-sm-none {
        margin-top: 15px;

        .slick-slide {
            padding: 0 2px;
        }

        @media (max-width: 667px) {
            display: none;
        }
    }

    @media (max-width: 667px) {
        margin-bottom: 10px;
    }

    img {
        &:hover {
            cursor: pointer;
        }
    }
}

.Toastify__toast--success {
    background-color: $primary;
}

.main-menu {
    // @media (max-width: 576px) {
    //     padding-top: 9px;
    //     padding-bottom: 9px;
    // }

    .pixelstrap {
        a {
            padding: 15px 25px;
        }
    }

    .menu-left {
        @media (max-width: 1200px) {
            flex: 1;

            .navbar {
                padding: 0;
            }
        }
        .brand-logo {
            padding-top: 15px;
            padding-bottom: 15px;

            @media (max-width: 1200px) {
                margin: 0 auto;
            }
        }
    }

    .sub-arrow {
        right: 0px !important;
    }

    .form-inline {
        a {
            &:hover {
                padding: 25px 30px 25px 0;
            }
        }
    }

    .live-auction-button {
        max-width: none;
        margin-left: 5px;
        display: flex;

        
        @media (max-width: 768px) {
            align-items: center;
        }

        &:hover {
            color: #fff;
        }
    }
}

.badge-primary {
    background-color: $secondary-color;
    font-size: 80%;
}

.breadcrumb-item {
    color: $primary-color;

    &.active {
        color: $primary-color;
        font-weight: 500;
    }
}

.react-datepicker-wrapper {
    display: flex !important;
}

.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    float: left;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: $grey-light;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  // .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  //   background-color: $grey-light;
  //   opacity: .8;
  // }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #28a745;
		color: #FFF;
  }
  
  // .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  //   background-color: $secondary-color;
  // }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    // border-color: $secondary-color;
  }
  
  // .react-toggle--focus .react-toggle-thumb {
  //   -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  //   -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  //   box-shadow: 0px 0px 2px 3px #0099E0;
  // }
  
  // .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  //   -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  //   -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  //   box-shadow: 0px 0px 5px 5px #0099E0;
  // }

	.react-toggle-2 .react-toggle-track {
		width: 60px;
	}

	.react-toggle-2.react-toggle--checked .react-toggle-thumb {
    left: 37px;
    // border-color: $secondary-color;
  }

  .slider {
      span {
        display: inline-block;
        margin-top: 3px;
        margin-left: 10px;
      }
  }

select {
    &.form-control {
        border: 0;
        outline: 1px solid #CCC;
        background-color: white;

        &.is-invalid {
            outline: 1px solid #dc3545;
        }

        &:focus {
            border: 0;
            outline: 1px solid #CCC;
            background-color: white;
            box-shadow: none;
        }
      }
}

.filepond--file {
    background-color: $secondary-color;
}

.css-vj8t7z {
    border-radius: 0 !important;

    &:focus {
        border-radius: 0 !important;
    }
}

.css-2o5izw {
    border-radius: 0 !important;
    &:hover {
        border-color: $secondary-color !important;
        border-radius: 0;
    }
}

.css-10nd86i {
    margin-bottom: 5px;
}

.react-select-error {
    .css-vj8t7z {
        border-color: #dc3545;
    }
}

.account-nav-dropdown {
    .small-dropdown-menu {
        // padding: 10px 20px 10px 5px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
        max-width: none !important;
        right: 0;
        left: auto !important;

        @media (max-width: 1200px) {
            box-shadow: none;
        }

        a {
            padding: 10px 25px;

            &:hover {
                padding: 10px 25px;
                cursor: pointer; 
            }
        }

        li {
            @media (max-width: 1200px) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }
}

.uncontrolled-collapse {
    border-top: solid 1px $bg-color2;
    padding: 10px;

    h3 {
        color: $black;
        margin-bottom: 0;

        i {
            float: right;
        }
    }
}

.collapse {
    p {
        opacity: .8;
    }
}

.pagination {
    .page-link {
        color: $secondary-color !important;
    }

    .page-item {
        &.active {

            .page-link {
                color: $white !important;
                background-color: $secondary-color !important;
            }
        }

        &.disabled {
            opacity: .5;
        }
    }

    .page-link {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    @media (max-width: 576px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.sticky {
    &.fixed {
        z-index: 100;
        display: table;

        .top-header {
            max-height: 60px;
        }
    }

    .header-dropdown {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 1200px) {
            display: block;
        }

        li {
            a {
                vertical-align: sub;
            }
        }
    }
}

.card-header:first-child {
    border-radius: 0;
}

section {
    padding-top: 15px;
}

.modal {
    .modal-title {
        font-size: 1.2rem;
    }
}

footer {
    p {
        line-height: 20px !important;
    }

    .d-xl-none {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: $white;
        z-index: 10;
        box-shadow: 0px 0px 8px #ddd;

        form {
            width: 100%;
            padding-right: 10px;
            padding-top: 10px;

            .form-control {
                border: 0;
                background-color: transparent;
                border-bottom: solid 1px $primary-color;

                &:focus {
                    box-shadow: none;
                }
            }

            .input-group-append {
                display: block;
                position: relative;

                .fa {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $primary-color;
                }
            }
        }
    }

    @media (max-width: 576px) {
        margin-bottom: 54px;
    }

    .footer-email {
        text-transform: lowercase;
    }
}

.sidenav {
    .input-group-append {
        margin-left: 3px;
    }
}

.modal-header {
    padding: 0.8rem;
}

.g-recaptcha-bubble-arrow {
    top: 435px !important;
}

.re-captcha-pos {
    top: 220px !important;
}

.flex-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1 1 100%;

    .btn {
        width: 100%;
        padding: 0.375rem 0.75rem;

        &:nth-of-type(even) {
            margin-left: 5px;
        }
    }
}

.cookie {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: $primary-color;
    z-index: 3147483639;
    border-bottom: 1px solid $light-grey-border;

    @media (max-width: 1200px) {
        height: auto;
        max-height: none;
    }

    .btn {
        @media (max-width: 576px) {
            margin: 10px 0 5px 0;
        }
    }
}

.atlwdg-trigger {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.rccs {
    margin: inherit !important;
}


#field-wrapper {
    label {
        margin-bottom: 0 !important;
    }
}

.credit-card-input {
    &#cvc {
        @media (max-width: 1024px) {
            width: 75px;
        }
    }
}


    
  