﻿@import "theme/_variables.scss";

@font-face {
  font-family: "MyriadPro-Bold";
  src: url("/assets/fonts/MyriadPro-Bold[1].otf");
}

@font-face {
  font-family: "MyriadReg";
  src: url("/assets/fonts/MyriadWebPro[1].ttf");
}

@font-face {
  font-family: "BebasNeue";
  src: url("/assets/fonts/BebasNeue-Regular.otf");
}

@font-face {
  font-family: "BebasBold";
  src: url("/assests/fonts/BebasNeueBold.ttf");
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
}

.container {
  @media (min-width: 576px) {
    max-width: none;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 991px) {
    max-width: 1000px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1400px;
  }
}

.primary-color-text {
  color: $primary-color;
}

.primary-color-btn {
  background: $primary-color;
  opacity: 0.9;
  color: "#FFF";
}

.primary-color-btn:hover {
  background: $primary-color;
  opacity: 1;
}

.top-header {
  background-color: $primary-color;

  @media (max-width: 767px) {
    display: none;
  }

  .header-contact {
    padding: 10px 0;
    li {
      color: $white;

      .fa {
        color: $white;
      }
    }
  }

  .header-dropdown {
    @media (max-width: 767px) {
      display: none;
    }
    li {
      padding: 5px 25px;

      &:hover {
        color: $white;

        i {
          color: $white;
        }
      }

      a {
        color: $white;

        &:hover {
          .fa {
            color: $white !important;
          }
        }
      }
    }

    .mobile-account {
      color: $white !important;

      li {
        color: $black;
      }
    }

    .fa {
      color: $white;
    }

    .navbar-input-group {
      .form-control {
        border: 0;
        background-color: transparent;
        border-bottom: solid 1px #fff;

        &:focus {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          color: $white;
        }
      }

      .input-group-append {
        .fa {
          margin-top: 7px;
        }
      }
    }
  }
}

.main-header-container {
  background-color: $header-menu-bg-color;
  height: 67px;
  display: flex;
  align-items: center;

  .header-logo {
    max-height: 65px;
  }
}

.cta-dark {
  background-color: $primary-color;
  margin-top: 55px;
  border-bottom: solid 1px #dddddd;

  h4 {
    font-size: 2rem;
  }
}

.dark-blue-bg {
  background-color: $primary-color;
}

.pixelstrap {
  a {
    &:hover {
      // color: $primary-color !important;
      padding: 15px 25px;
    }
  }

  ul {
    li {
      border-top: 0;
      a {
        color: black;

        &:hover {
          color: $primary-color;

          @media (max-width: 1199px) {
            color: $primary-color !important;
          }
        }

        &:before {
          background-color: $primary-color !important;
        }
      }
    }
  }

  .link-section {
    .menu-title {
      a {
        &:before {
          background-color: transparent !important;
        }
      }

      h5 {
        margin-bottom: 0;
      }
    }

    .menu-content {
      @media (max-width: 1200px) {
        padding: 6px 2px;
      }
    }

    h5 {
      font-family: $myriadPro-Bold;
    }

    ul {
      li {
        a {
          @media (max-width: 1199px) {
            padding-top: 3px !important;
            padding-bottom: 3px !important;
          }
        }
      }
    }
  }

  .full-mega-menu {
    max-height: calc(100% - 115px);
    overflow-y: auto;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);

    @media (max-width: 1200px) {
      padding: 0 !important;
    }

    .mega-box {
      display: flex;
      justify-content: flex-start;
      max-width: 100%;

      @media (max-width: 1200px) {
        display: block;
      }

      .menu-title {
        h5 {
          margin-bottom: 0;
        }
      }

      .menu-content {
        margin-bottom: 5px;

        li {
          a {
            padding: 0;

            &:hover,
            &:focus {
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
          }
        }
      }

      .link-section {
        margin-right: 15px;
      }
    }
  }

  .category-nav-container {
    position: relative;
    .mega {
      padding: 3px 6px;

      &.active {
        background-color: $primary-color;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 15px solid $primary-color;
          position: absolute;
          right: 0px;
          top: 0;
        }

        h5 {
          color: $white;
          margin-bottom: 0;
        }
      }
    }

    .block-nav {
      display: block;
      position: relative;
      height: 100%;
      top: 0;
      overflow: auto;

      @media (max-width: 1200px) {
        position: relative;
      }

      li {
        a {
          padding: 5px 0;
        }
      }
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.cat-nav {
  padding: 0;

  @media (max-width: 1200px) {
    border-top: none;
    margin-top: 5px;
  }

  .nav {
    @media (min-width: 1400px) {
      max-width: 1400px;
      margin: 0 auto;
    }
  }
  .nav-link {
    @media (max-width: 1285px) {
      padding: 0.5rem;
    }
  }
}

.category-nav-sidebar {
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
}

.display-none {
  @media (max-width: 1200px) {
    display: none !important;
  }
}

#main-nav {
  .sidebar-overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #212331;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 1s;
    transition: all 1s;
    @media (min-width: 1200px) {
      display: none !important;
    }
  }

  &.hasOverlay {
    .sidebar-overlay {
      visibility: visible;
      opacity: 0.8;
    }
  }
}

#main-menu {
  z-index: 9999;

  &.open-menu {
    right: 0 !important;

    .menu-content {
      li {
        a {
          &:hover {
            padding-top: 3px !important;
            padding-bottom: 3px !important;
          }
        }
      }
    }
  }

  .mega {
    .has-submenu {
      color: $header-menu-text-color;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: 1199px) {
        border-bottom: inset 1px rgba(0, 0, 0, 0.2);
      }
    }
    .cat-nav {
      .mega-box {
        border-bottom: inset 1px rgba(0, 0, 0, 0.2);

        .menu-content {
          a {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          @media (max-width: 1200px) {
            margin-bottom: 0;
          }
        }
      }

      .menu-title {
        @media (max-width: 1200px) {
          display: none;
        }
      }

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .full-mega-menu {
      @media (max-width: 1200px) {
        box-shadow: none;
      }

      .container {
        @media (max-width: 1200px) {
          width: 100%;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    &.account-nav-dropdown {
      li {
        @media (max-width: 1200px) {
          border-bottom: inset 1px rgba(0, 0, 0, 0.2);

          a {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.loremIpsum {
  font-family: "myriad";
  font-weight: bold;
  font-style: italic;
  font-size: 1.2em;
  border: 2px solid red;
  margin-bottom: 1rem !important;

  p {
    color: red;
  }
}
