.browser-wrapper {
    justify-content: space-evenly;
    flex-wrap: wrap;

    .browser-option-container {

        @media (max-width: 576px) {
            flex: 1 1 100%;
        }

        img {
            max-width: 150px;
            max-height: 150px;
            padding-bottom: 10px;
            margin: 0 auto;

            @media (max-width: 576px) {
                padding-top: 10px;
            }
        }

        .btn-wrapper {
            margin: 0 auto;

            .btn {
                max-width: 225px;
                margin: 0 auto;
            }
        }
    }
}