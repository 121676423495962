.auctionTile {
    margin-bottom: 25px;
    min-height: 156px;
    .div{
        height: 150px;
    }
    %panel{
        border: 1px solid rgba(0, 0, 0, 0.125); 
    }
    .leftPanel {
        @extend %panel;
        border-right:none;
        text-align:center;
        border-right: none;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-top-left-radius: .25em;
        border-bottom-left-radius: .25em;
}
        @media (max-width: 540px) {
            border: 0;
            border-top: 2px solid rgba(0, 0, 0, 0.125);
            padding-top: 15px;
            padding-bottom: 15px;
        .grayLargeLabel {
            margin-top: -25px;

            @media (max-width: 768px) {
                margin-bottom: 0;
            }

            @media (max-width: 576px) {
                margin-top: 5px;
            }
        }
    }
    .rightPanel{
        border-top-right-radius: .25em;
        border-bottom-right-radius: .25em;
        padding: 25px;
        @extend %panel;

        @media (max-width: 576px) {
            display: block;
            padding: 15px;
            border: 0;
            border-top: 2px solid rgba(0, 0, 0, 0.125);
        }

        .rightPanel-flex {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            @media (max-width: 576px) {
                display: block;
                margin-top: 15px;
            }

            .btn {
                width: 100%;
                border-radius: 0;
                // max-width: 160px;

                @media (max-width: 576px) {
                    max-width: 100%;
                }

                .boldLinkButton {
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }

    }
    .calendarIcon{
        color: #2B3C4A;
        flex: 0 0 100%;
    }
    .calendarText{
        margin-top:.1em;
        color:#2B3C4A;
    }
    %largeLabel{
        font-size:1.3em;
    }
    .boldLargeLabel{
        @extend %largeLabel;
        font-weight: bold;
    }

    .grayLargeLabel{
            @extend %largeLabel;
            font-variant: small-caps;
            color:gray;
        }
}

.online-auctions {
    .buttonDropdown {
        @media (max-width: 576px) {
            float: left;
            width: 100%;

            .btn {
                max-width: 100%;
                width: 100%;
                margin-bottom: 15px;
            }
        }
    }
}