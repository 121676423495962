﻿@import '../scss/theme/_variables.scss';
@import '../scss/font-awesome/variables.scss';

.item {
    .description {
        font-weight: bold;
    }

    .biddingContainer {
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;

        .winning-offer {
            padding: 5px 0;
            box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);

            @media (max-width: 576px) {
                padding-right: 5px;
                padding-left: 5px;
            }

            h6 {
                margin: 0 auto;
                color: $white;
            }
        }

        .is-invalid {
            &:focus {
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }

        .loader-spin-12 {
            margin: 0 auto;
            margin-bottom: 5px;
            margin-top: 5px;
            top: -17px;
        }

        form {
            .btn {
                @media (max-width: 480px) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .actions-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;

        .form-check-input {
            position: relative;
            margin: 0 10px 0 0;
        }

        .inline-check-label {
            margin-bottom: 0;
        }
    }

    .bidIncrement {
        font-size: 0.8rem;
    }

    .startingBid {
        font-size: 0.9rem;
    }

    .bidsNumber {
        display: flex;
        justify-content: flex-end;
    }

    .biddings {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .placeBid {
        margin-bottom: 1rem;

        .price-container {
            display: flex;
            justify-content: space-between;
            padding-top: .8rem;
            padding-bottom: 0.7rem;
            background-color: #efefef;
            box-shadow: 0px 3px 1px rgba(0,0,0,.1);

            @media (max-width: 480px) {
                padding: 5px .7rem;

                .vertical-hr {
                    margin: 0 22px;
                }
            }

            .watchContainer {
                display: flex;
                justify-content: space-between;
                width: 200px;

                @media (max-width: 480px) {
                    justify-content: space-around;
                    width: auto;
                }

                .btn {
                    border-radius: 3px;
                    background-color: $white;
                    border: solid 1px $bg-color2;
                    max-height: 36px;

                    @media (max-width: 480px) {
                        margin-left: 2px;
                    }

                    i {
                        color: $secondary-color;
                    }
                }

                span {
                    padding: 10px 0;
                }

                .totals-container {
                    @media (max-width: 480px) {
                        margin: 0 2px;
                    }
                }
            }
        }
    }

    .itemDetailsHeading {
        margin-bottom: 0.5rem;
    }

    .startingBidValue {
        color: $secondary-color;
        font-size: 1.6rem;
        font-weight: bold;
        font-family: $myriadPro-Bold;

        span {
            color: $black;
            font-weight: normal;
            font-size: .8rem;
            font-family: $myriadReg;
            margin-left: 2px;
        }

        .bidIncrement {
            position: absolute;
            bottom: 0;
            color: $black;
            font-family: $myriadReg;

            span {
                color: $secondary-color;
                font-family: $myriadPro-Bold;
            }
        }
    }

    .bidsNumberSmall {
        font-weight: 100;
    }

    .itemInfo {
        @media (max-width: 995px) {
            margin-top: 15px;
        }
        @media (max-width: 576px) {
            border-bottom: 0.5px solid $grey-lighter;
            margin-bottom: 1rem;
        }
    }

    .specHeader {
        background-color: $primary-color;
        color: white;
        font-weight: bold;
        padding: 15px 0 15px 10.5px;
    }

    .assurance {
        background-color: #F5F5F5;
        padding-top: 1rem;
        margin-bottom: 1rem;
    }

    h5 {
        color: $secondary-color;
    }

    .show-more {
        transition: 2s all;
    }

    table {
        background-color: $light-grey;

        tbody + tbody {
            border-top: 1px solid $bg-color2;
        }
    }

    .link-btn {
        display: block;
        border: 1px solid $bg-color2;
        padding: 10px 10px;
        margin-top: 15px;
        color: $black;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
        font-family: $bebasNeue;

        &:after {
            font-family: 'FontAwesome';
            content: $fa-var-external-link;
            position: absolute;
            right: 30px;
            color: $secondary-color;
        }

        &.offers {
            width: 100%;
            text-align: center;

            &:after {
                content: '';
            }
        }
    }

    .offer-container {
        padding-right: 0;

        @media (max-width: 767px) {
            padding-right: 15px;
        }
    }

    .loader-img-container {
        height: 100vh;
        max-height: 540px;
    }

    h6 {
        font-size: 1.3rem;
        vertical-align: middle;
    }

    .btn-wrapper {
        margin-bottom: 15px;
    }

    .biding-container {
        padding-left: 15px;
        padding-right: 15px;
        flex: 0 0 100%;
        margin: 0;

        &.box-shadow {
            box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        }

        h4 {
            margin: 0 !important;
            font-size: 1.3rem;
        }

        .bid-status-container {
            
            h6 {
                margin-top: 10px;
                color: $black;
                width: 100%;
                order: 1;
            }

            .bid-button-wrapper {
                display: flex;
                justify-content: space-between;
                padding: 0 15px 15px 15px;

                .btn {
                    font-size: 1rem;

                    &.bid {
                        flex: 0 0 69%;
                        order: 2;
                        margin-right: 7.5px;
    
                    }
                    &.max {
                        order: 3;
                        flex: 0 0 28%;
                    }
    
                    &.cancel {
                        border-color: $light-grey-border;
                    }
                }
            }
            
            &.message {
                display: flex;
                justify-content: center;
                padding: 9px;

                .bid-button-wrapper {
                    display: block;
                    padding: 10px;

                    h5 {
                        font-size: 2.2rem;
                        text-align: center;
                    }

                    span {
                        margin-bottom: 0;
                        font-size: 1.2rem;
                        text-align: center;
                    }
                }
            }
        }

        &.highest {
            background-color: #28a745;

            h6 {
                color: $white;
            }
            
            .max {
                flex: 0 0 100% !important;
                background-color: $white;
                color: $secondary-color !important;
                border-color: $white;
            }
        }

        &.outBid {
            background-color: #dc3545;

            h6 {
                color: $white;
            }

            .btn {
                background-color: $white;
                background-color: $white;
                color: $secondary-color !important;
                border-color: $white;
            }
        }

        .message-container {
            padding: 8px;
            
            h6 {
                margin: 0 auto;
                color: #000;
            }
        }
    }

    .max-bid-container {
        border: solid 1px $bg-color2;

        .confirm-bid-wrapper {
            .cancel {
                flex: 25%;
            }
        }
    }

    .bid-values {
        display: flex;
        flex: 0 0 100%;
        justify-content: space-between;
        padding: 14px 15px 5px 15px;
        margin: 0;
        background-color: #efefef;
        box-shadow: 0px 3px 1px rgba(0,0,0,.1);

        .amount-container {
            order: 1;

            h4 {
                @extend %h4;
                span {
                    font-family: $myriadReg;
                    font-size: 1rem;
                    color: $black;
                }
            }
        }

        .timer-container {
            order: 3;
            display: flex;
            justify-content: space-between;
            width: 200px;

            .vertical-hr {
                order: 1;
            }

            div {
                order: 2;
                display: flex;
                h4 {
                    order: 2;
                    @extend %h4;
                    margin-left: 10px;
                }

                span {
                    order: 1;
                    margin-top: 7px;
                }
            }

        }

        %h4 {
            font-size: 1.8rem;
            color: $secondary-color;
        }
    }

    [aria-controls='analytics'] {
        margin-bottom: 15px;
        border: solid 1px $bg-color2;
    }
}

.content-row {
    .purchase-row {
        order: 1;
    }

    .finance-col {
        order: 3;
    }

    .item-ended {
        transform: translate(-50%, -50%);
        font-size: 7rem;
        text-align: center;
        line-height: normal;
    }
}

.buttonDropdown {
    float: right;

    button{
        font-weight: bold;
    }
}

[aria-controls="INSPECTIONREPORT"] {
    background-color: #2B3C4A;
}

#INSPECTIONREPORT{
    color: white;
}

.analytics {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .card {
        overflow:hidden;

        h6 {
            color: $black;
            font-size: 1.2rem;
        }

        h5 {
            color: $primary-color;
            font-size: 1.4rem;
            z-index: 100;
        }
    }
    
    .card-body {
        padding: 1.15rem;
        z-index: 1;

        .rotate {
            z-index: 0;
            float: right;
            height: 100%;
        }
    } 
    
    .card-body .rotate i {
        color: $white;
        position: absolute;
        left: 0;
        left: auto;
        right: -10px;
        bottom: 0;
        display: block;
        -webkit-transform: rotate(-44deg);
        -moz-transform: rotate(-44deg);
        -o-transform: rotate(-44deg);
        -ms-transform: rotate(-44deg);
        transform: rotate(-44deg);
        z-index: -1;
    }

    .col {
        margin-bottom: 15px;

        @media (max-width: 768px) {
            flex: 0 0 50%;
        }
    }
}

.analyticsDetails {
    max-width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 10px;
    padding: 0 10px 10px 10px;
    color: #2B3C4A;
    font-size: .9rem;
    
    a {
        color: #005DA8;
    }

    .row{
        padding: 5px 0px;
        margin-left: 5px;
    }

    .striped {
        background-color: #EFEFEF;
    }
}

.slide-wrapper {
    img {
        max-height: 499px;
        object-fit: contain;
        margin: 0 auto;
    }
}

.carousel-thumb-wrapper {
    img {
        height: 100px;
        object-fit: fill;
        padding: 2.5px;

        @media (max-width: 480px) {
            max-height: 70px;
        }
    }
}

.module-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-transform: uppercase;

    li {
        color: $black;
        font-size: 1rem;
        margin: 9px;

        &:hover {
            opacity: .7;
            text-decoration: underline;
        }
    }
}

.detail-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 480px) {
        display: block;
    }

    div {
        text-transform: uppercase;
        font-weight: bold;
        font-size: .8rem;
    }
}

.jump-p {
    margin-top: 1rem;
    margin-bottom: -10px;
}

