
.home-page-container {
    margin-top: 93px;

    @media (max-width: 1200px) {
        margin-top: 52px;
    }

    @media (max-width: 576px) {
        margin-top: 52px;
    }

    .header-container {
        position: relative;
        display: flex;
        background-image: url('/assets/images/home/hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        justify-content: center;
        background-position: center;
        height: 386px;
        width: 100%;
        max-width: 100%;

        @media (max-width: 576px) {
            font-size: 3rem;
            height: 300px;
        }

        .heading-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(3%, -58%);

            @media (max-width: 1260px) {
                transform: translate(-7%, -58%);
            }

            @media (max-width: 1060px) {
                transform: translate(-50%, -50%);
                width: 100%;
            }

            @media (max-width: 576px) {
                padding: 15px;
            }
            

            h1 {
                color: $white;
                font-size: 5.5rem;
                letter-spacing: 5px;
                text-align: center;
                line-height: 1;
                margin-bottom: 0;

                @media (max-width: 576px) {
                    font-size: 3.1rem;
                }
            }

            h5 {
                color: $white;
                font-family: $myriadReg;
                font-size: 1.6rem;
                text-align: center;
                margin: 0 auto;              
                border-top: 3px solid #fff;
                border-bottom: 3px solid #fff;
                display: inline-block;
                padding-top: 8px;
                padding-bottom: 8px;

                @media (max-width: 576px) {
                    font-size: 1.3rem;
                }
            }

            .img-wrapper {
                @media (max-width: 576px) {
                    margin-top: 0 !important;
                }
                

                img {
                    @media (max-width: 576px) {
                        max-width: 150px;
                    }
                }
            }
        }
    }
}

.home {
    position: relative;

    .slider-contain {
        position: absolute;
        top: 40%;
        left: 50%;
        display: block;

        @media (max-width: 600px) {
            position: relative;
            top: 0;
            left: 0;
        }

        h2 {
            color: $white;
            text-shadow: 1px 1px rgba(0,0,0,.5);
            text-align: left;
        }

        .btn-solid {
            float: left;
        }

        .simplifty-container {
            background-color: rgba(0, 0, 0, 0.7);
            height: 220px;
            padding: 35px;
            width: 390px;

            @media (max-width: 600px) {
                height: 300px;
                width: 100%;
                padding: 16px;
            }

            .btn {
                @media (max-width: 600px) {
                    margin-bottom: 5px;
                }
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 576px) {
            min-height: 166px;
        }
    }

    .live-auction-banner {
        position: relative !important;
        background-color: transparent !important;
        top: 0;
        padding: 0;
        z-index: 0;
        width: 100%;
        border: solid 1px $light-grey-border;
        margin-bottom: 25px;       
    
        %flex {
            display: flex;
            justify-content: space-between
        }
    
        .banner-message-container {
            @extend %flex;
            flex: 0 0 100%;
            background-color: $primary-color;
            padding: 25px;
    
    
            .banner-wrapper {
                @extend %flex;
                flex: 0 0 100%;
                justify-content: space-between;
                padding: 0 15px;

                @media (max-width: 767px) {
                    display: block;
                    padding: 0;
                }
    
                .event-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    flex: 0 0 40%;
                    max-width: 400px;

                    @media (max-width: 576px) {
                        flex: 0 0 100%;
                        max-width: none;
                    }

                    span {
                        @media (min-width: 1200px) {
                            width: 100%;
                        }

                        @media (max-width: 991px) {
                            margin-left: 5px;
                        }
                    }
                }
        
                .date-wrapper {
                    @extend %flex;
                    flex: 0 0 60%;
                    justify-content: space-between;
                    max-width: 560px;

                    @media (max-width: 576px) {
                        flex: 0 0 100%;
                        max-width: none;
                        display: block;
                    }
        
                    h3 {
                        margin: auto 0 auto 35px;

                        @media (max-width: 991px) {
                            margin: 0 5px 0 auto;
                        }

                        @media (max-width: 768px) {
                            margin: 0;
                        }
                    }

                    a {
                        @media (max-width: 996px) {
                            max-height: 50px;
                        }

                        @media (max-width: 576px) {
                            width: 100%;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .live-auction-button {
                max-width: 90px;
            }
        }
    
        h2 {
            margin-right: 10px;
            margin-bottom: 0;
            color: $white;
        }
    
        .list-item-wrapper {
            background-color: $white;
            padding: 25px;

            @media (max-width: 576px) {
                padding: 5px;
            }
    
            .category-count-wrapper {
                @extend %flex;
                flex: 0 0 100%;
                flex-wrap: wrap;
                padding-bottom: 15px;
                justify-content: flex-start;
    
                li {
                    font-size: 1rem;
                    padding: 5px;
    
                    .fa {
                        font-size: 8px;
                        vertical-align: middle;
                    }
                }
            }
    
            .img-wrapper {
                position: relative;
    
                &:hover {
                    cursor: pointer;
    
                    .description {
                        display: block;
                        color: $white;
                        text-align: center;
                    }
                }
    
                img {
                    max-height: 198px;
                    min-height: 198px;
                    padding: 0 7.5px;
                    object-fit: cover;
                    margin: 0 auto;

                    @media (max-width: 576px) {
                        max-height: none;
                        min-height: none;
                        padding: 0;
                        margin: auto;
                    }
                }
    
                .description {
                    position: absolute;
                    bottom: 0;
                    background-color: #000000;
                    opacity: .7;
                    width: -webkit-fill-available;
                    margin: 0 7.2px;
                    left: 0;
                    padding: 5px 7px;
                    display: none;
                }
            }
        }
    }
}

.header-dropdown {
    @media (max-width: 1388px) {
        margin-left: 0;
    }

    .onhover-dropdown {
        &:before {
            right: -15px;
        }
    }

    .navbar-input-group {
        max-height: 30px;
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: white;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: white;
          }

        .form-control {
            height: 30px;

            &:focus {
                border-color: $white; 
                box-shadow: none;
            }
        }

        .input-group-append {
            height: 30px;

            .input-group-text {
                background-color: $secondary-color;
                border-color: $secondary-color;
                color: $white;
            }
        }
    }

    .form-group {
        margin-bottom: 0;
    }
}

.title-inner1 {
    &:after {
        background-color: $primary-color !important;
    }
}

.upcomming {
    margin-top: 25px;
}

.rememberForgot {
    display: flex;
    justify-content: space-between;

    .form-check-label {
        position: relative;
        top: auto;
        transform: none;

        .form-check-input {
            position: relative;
            width: auto;
            height: auto;
            margin: 0 0 0 -1.25rem;
        }
    }
}

.onhover-dropdown {
    .onhover-show-div {
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);

        li {
            a {
                &:hover {
                    color: $primary-color !important;
                }
            }
        }
    }
}


.subscribe-form {
    align-items: inherit !important;
    height: auto !important;

    .form-control {
        @media (max-width: 768px) {
            height: 100%;
        }
    }
}

.heading-wrapper {
    display: flex;
    justify-content: space-between;

    a {
        margin: auto 0;
        color: $primary-color;
        font-size: 16px;
    }
}

.upcoming-container {
    border: solid 1px $light-grey-border;
    padding: 25px;
    margin-bottom: 35px;
    width: 100%;
    position: relative;

    @media (max-width: 576px) {
        padding: 5px;
    }

    .slick-slide {
        height: auto;
        // width: auto !important;
        img {
            max-height: 198px;
            min-height: 198px;
            padding: 0 7.5px;
            object-fit: cover;
            margin: 0 auto;

            @media (max-width: 576px) {
                max-height: none;
                min-height: none;
                padding: 0;
                margin: auto;
            }
        }
    }

    h3 {
        color: $primary-color;
        font-family: $myriadReg;
    }

    h5 {
        font-family: $myriadReg;
        text-transform: uppercase;

        span {
            text-transform: none;

            @media (max-width: 576px) {
                margin-right: 20px;
            }
        }

        span + span {
            margin-left: 10px;
            @media (max-width: 576px) {
                margin: 0;
            }
        }

        a {
            position: absolute;
            right: 25px;
            top: 25px;
            text-transform: none;
            color: $secondary-color;

            @media (max-width: 576px) {         
                position: relative;
                top: 0;
                right: 0;
                display: block;
            }
        }
    }

    .img-wrapper {
        position: relative;

        .item-description {
            position: absolute;
            bottom: 0;
            background-color: #000000;
            opacity: .7;
            width: -webkit-fill-available;
            margin: 0 7.2px;
            left: 0;
            padding: 5px 7px;
            display: none;


            p {
                color: $white;
                margin-bottom: 2px;
                text-align: center;
            }
        }

        &:hover {
            .item-description {
                display: block;
            }
        }
    }

    .slick-slider {
        @media (max-width: 576px) {
            margin-bottom: 0;
        }
    }
}

.small-banner-container {
    display: flex;
    flex: 1;
    margin-top: 65px;

    .bordered-container {
        position: relative;
        padding: 25px;
        border: 1px solid #DFE3E9;
    
        .cta-container {
            p {
                font-size: 1.1rem;
            }
    
            h3 {
                color: $black;
            }

            .btn {
                max-width: 150px;
                width: 100%;
            }
        }
    }
    
    .small-banner {
        background-image: url('/assets/images/equipment/inventory-hero-block.png');
        background-size: cover;
        display:flex;
        justify-content:center;
        align-items:center;
    }
}

.service-block-wrapper {
    display: flex;
    flex: 1;
    margin-top: 65px;

    @keyframes pulse {
        0% { transform: scale(1); }
        50% { transform: scale(1.05); }
    }

    .service-block svg path {
        fill: $primary-color;
    }

    .service-block:hover h4 {
        color: $primary-color !important;
    }

    .service-block + .service-block {
        margin-top: 0;
    }

    .service-block:hover svg {
        animation: pulse 1s linear;
        animation-timing-function: linear;
        transition: none;
      }

      .pulse-img {
        margin-right: 12px;
        height: auto;
        
        &:hover {
            animation: pulse 1s linear;
            animation-timing-function: linear;
        }
    
        @media (max-width: 991px) {
            max-width: 46px;
            margin-bottom: 20px;
        }
    
        @media (max-width: 767px) {
            margin-right: 0;
        }
    }
}

$border: 1px solid $bg-color2;

.cta-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 65px;

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;

        &.justify-small-stack {
            flex-direction: column-reverse;
        }
    }

    .card {
        display: flex;
        justify-content: center;
        flex: 1 1 50%; 
        border-radius: 0;
        border-top: $border;
        border-bottom: $border;
        border-left: $border;
        padding: 25px;


    @media (max-width: 576px) {
        flex: 1 1 100%;
    }

        &.justify-content-right {
            display: block;

            @media (max-width: 767px) {
                display: flex;
            }

            .btn {
                float: right;
            }

            h3, p  {
                text-align: right;

                @media (max-width: 576px) {
                    text-align: left;
                }   
            }
        }

        .btn {
            max-width: 230px;
        }

        p {
            font-size: 1.1rem;
        }
    }

    h3 {
        color: $primary-color;
    }

    img {
        max-width: 50%;
        object-fit: cover;
        object-position: center;

        @media (max-width: 576px) {
            max-width: 100%;
            height: 163px;
        }
    }
}

.slide-3 {
    .slick-prev {
        left: -5px;
    }

    .slick-next {
        right: -5px;
    }
}

.flex-1 {
  flex: 1;
}