@import  '../scss/theme/_variables.scss';

%flexTable {
    display: flex;
    justify-content: space-evenly;
    flex: 0 0 100%;
}

%border-bottom {
    border-bottom: solid 1px $light-grey-border;
}

.flexTable {
    .flexTableHead {
        @extend %flexTable;
        border-top: solid 1px $light-grey-border;

        .flexTableRow {
            background-color: $white;
            color: $black;
            @extend %border-bottom;
            
            .flexTableData {
                font-family: $bebasNeue;
                color: $grey;
                text-transform: uppercase;
            }

            &:last-of-type {
                @extend %border-bottom;
            }
        }

        @media (max-width: 990px) {
            display: none;
        }
    }

    .flexTableRow {
        @extend %flexTable;
        @extend %border-bottom;

        &:last-of-type {
            border-bottom: 0;
        }

        @media (max-width: 990px) {
            display: block;
        }

        .flexTableData {
            padding: 0.75rem 0;
            flex: auto;
            margin: auto 0;
            word-break: keep-all;

            @media (max-width: 990px) {
                padding: 10px 5px 0 5px;
                border-bottom: solid 1px $light-grey-border;

                &:last-of-type {
                    border-bottom: 0;
                }

                .fa-file-pdf-o {
                    display: none;
                }
            }

            &:first-of-type {
                border-left: 0;
            }

            @for $value from 0 through 100 {
                &.fixed-width-#{$value} {
                    flex: 0 0 #{$value}% !important;

                    @media (max-width: 990px) {
                        flex: auto !important;
                    }
                }
            }

            img {
                width: 100%;
                max-width: 150px;
                max-height: 150px;
                object-fit: cover;
                padding-right: 10px;

                @media (max-width: 990px) {
                    max-width: none;
                }
            }

            .m-flexTableHead {
                font-weight: bold;
                margin-right: 5px;
                display: none;
                color: #23282c;

                @media (max-width: 990px) {
                    display: inline-block;
                }
            }

            &.right {
                text-align: right;

                @media (max-width: 990px) {
                    text-align: left;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }

        &.striped {
            background-color: rgba(0,0,0,.05);
        }
    }

    @for $value from 0 through 100 {
        &.tableWidth-#{$value} {
            max-width: #{$value}% !important;
        }
    }
}