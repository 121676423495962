
.center1000{
    max-width: 1000px;

    .order-container {
        padding: 15px 0 15px 0;

        h3 {
            color: $primary-color;
        }


        h5, h4 {
            margin-bottom: 0;
            font-size: 1.5rem;
        }
        
        h4 {
            color: $secondary-color;
            float: right;
        }

        p {
            span {
                margin-right: 5px;
            }
        }

        img {
            max-width: 210px;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (max-width: 767px) {
                max-width: none;
                padding-bottom: 15px;
            }
        }

        .disclaimer {
            font-size: 1rem;
            line-height: 1.2;
        }

        .btn-white {
            border-color: $light-grey-border;

            @media (max-width: 576px) {
                margin-bottom: 10px;
            }
        }

        &.confirmation {
            .congrats {
                background-color: $light-grey;
                padding: 15px 25px;
                margin-bottom: 15px;

                h4 {
                    float: none;
                    margin-bottom: 5px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}


