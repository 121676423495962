﻿@import 'theme/_variables.scss';

$container-padding: 10px;

.itemsContainer {
    display: flex !important;
    justify-content: center;
}

.itemCard {
    display: flex !important;
    flex-direction: column;
    // width: 250px;
    // max-width: 350px;
    flex: 0 0 25%;
    height: 100%;
    flex-grow: 0;
}

.info {
    border: 0.5px solid $bg-color2;
    position: relative;

    .floating-cta {
        position: absolute;
        top: -15px;
        left: 50%;
        background-color: $white;
        color: $primary-color;
        width: 100%;
        max-width: 180px;
        box-shadow: 0 0 1px rgba(0,0,0,.7);
        border: solid 1px $bg-color2;
        transform: translateX(-50%);
        padding: 4px;
        border-radius: 12px;
        font-size: .8rem;
        font-weight: 600;
        text-align: center;
    }
}

.infoContainer {
    padding: 10px;
    color: $primary-color;
    //max-height: 136px;
    height: auto;
    margin-top: 15px;

    h3 {
        height: 100vh;
        max-height: 55px;
        color: $primary-color;
    }
}

.meterReading {
    display: flex;
    justify-content: space-between;
    padding-top: 0;

    p {
        margin-bottom: 2px;
    }
}

.buyingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $container-padding;
    color: $primary-color;

    &.saleDescription {
        border-top: solid 1px $bg-color2;
        text-align: center;
        display: flex;
        justify-content: center;            

        h4 {
            margin-bottom: 0;
        }

        &:hover {
            background-color: $secondary-color;

            h4 {
                color: $white;
                text-decoration: underline;
            }
        }
    }
}


.sellTime {
    font-size: 0.8rem;
}

.pricingSection {
    color: $secondary-color;
}

.actions {
    padding: $container-padding;
    justify-content: space-between;
}

.gray {
    color: $primary-color;
    background-color: transparent;
}

.detailsButton {
    height: 33.3px;
    width: 100%;
    background-color: $primary-color;
    border-color: $primary-color;
}

.watchButton {
    height: 33.3px;
    width: 20%;
    margin-left: 5%;
    background-color: $primary-color;
    border-color: $primary-color;
}
img {
    &.featured {
        width: auto;
        min-width: 100%;
        min-height: 100%;
        border-top: 1px solid $bg-color2;
        border-right: 1px solid $bg-color2;
        border-left: 1px solid $bg-color2;

        @media (min-width: 1200px) {
            object-fit: cover;
            max-height: 184px;
            min-height: 184px;
        }

        @media (max-width: 1200px) {
            min-height: 210px;
            max-height: 210px;
            object-fit: cover;
        }

        @media (max-width: 991px) {
            max-height: 236px;
            min-height: 236px;
        }

        @media (max-width: 767px) {
            max-height: 169px;
            min-height: 169px;        
        }

        @media (max-width: 576px) {
            min-height: 223px;
            max-height: 384px;
        }

        @media (max-width: 480px) {
            max-height: 223px;
        }
    }
}

.img-wrapper {

    .favicon-container {
        position: absolute;
        right: 20px;
        top: 5px;
        z-index: 20;

        .watch {
    
            &:hover {
                color: red !important;
                cursor: pointer;
            }
        }
    }
}
