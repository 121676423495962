@import "theme/_variables.scss";

.auction {
  .card {
    display: block;
    border-radius: 0;
    margin-bottom: 15px;

    img {
      box-shadow: 0px 0px 1px 0px rgba(212, 212, 212, 0.6);
      min-height: 208px;
      max-height: 208px;
      margin: 0 auto;
      object-fit: contain;

      @media (min-width: 1370px) {
        max-height: 239px;
        min-height: 239px;
        margin: 0 auto;
      }

      @media (max-width: 1200px) {
        min-height: auto;
        max-height: auto;
        width: 100%;
        object-fit: cover;
      }
    }

    .auction-item-container {
      margin: 0;
      box-shadow: 0px 1px 1px 0px rgba(212, 212, 212, 0.5);
      padding: 5px 0 0 0;
      height: 100%;
      color: $primary-color;
      h3 {
        color: $black;
        min-height: 55px;
      }

      h4 {
        margin-top: 5px;
        font-size: 1.5rem;
        color: $secondary-color;
      }

      .text-white {
        color: $white;
      }
      .text-black {
        color: $black;
      }

      .bid-values {
        display: flex;
        justify-content: space-between;

        .amount-container {
          order: 1;
        }

        .vertical-hr {
          order: 2;
        }

        .timer-container {
          order: 3;
        }
      }

      .biding-container {
        border-top: 1px solid rgba(212, 212, 212, 0.5);
        box-shadow: 0 -5px 5px -5px rgba(212, 212, 212, 0.6) !important;
        margin-top: 15px;

        .bid-status-container {
          h6 {
            margin-top: 6px;
            color: $black;
            width: 100%;
            order: 1;
          }

          .bid-button-wrapper {
            display: flex;
            justify-content: space-evenly;
            padding: 0px 0 15px 0;

            .btn {
              font-size: 1rem;
              min-height: auto;

              @media (max-width: 1400px) and (min-width: 1200px) {
                font-size: 0.8rem;
              }

              @media (max-width: 700px) and (min-width: 575px) {
                font-size: 0.7rem;
              }

              &.bid {
                flex: 1;
                order: 2;
                margin-right: 7.5px;
              }
              &.max {
                order: 3;
              }

              &.cancel {
                border-color: $light-grey-border;
              }

              &.disabled {
                cursor: not-allowed;
              }
            }
          }

          &.message {
            display: flex;
            justify-content: center;
            padding: 10px;
            margin: 0;

            .bid-button-wrapper {
              display: block;
              padding: 10px;

              h5 {
                font-size: 2.2rem;
                text-align: center;
                color: $secondary-color;
              }

              span {
                margin-bottom: 0;
                font-size: 1.2rem;
                text-align: center;
              }
            }
          }
        }

        &.highest {
          background-color: #28a745;

          h4 {
            color: $white;
          }

          .max {
            flex: 0 0 100%;
            background-color: $white;
            color: $secondary-color !important;
            border-color: $white;
          }
        }

        &.outBid {
          background-color: #dc3545;

          h6 {
            color: $white;
          }

          .btn {
            background-color: $white;
            background-color: $white;
            color: $secondary-color !important;
            border-color: $white;

            &.disabled {
              cursor: not-allowed;
            }
          }
        }

        .message-container {
          display: flex;
          justify-content: center;
          padding: 13px 10px;

          h6 {
            margin: 0 auto;
            color: $secondary-color;
            font-size: 1.4rem;
          }
        }
      }

      span {
        font-size: 0.7rem;
      }

      .error-text {
        font-size: 0.9rem;
      }
    }

    .img-wrapper {
      position: relative;
      // display: flex;
      // align-items: flex-start;
      height: 100%;

      .btn {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 3px;
        background-color: #ffffff;
        border: solid 1px #bfbfbf;
        max-height: 36px;
        z-index: 99;
      }
    }

    .board-shift {
      background-color: rgba(0, 0, 0, 1);
      opacity: 0.5;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
}

@keyframes blink-blue {
  50% {
    border: 4px solid #005da8;
  }
}

.flashing-border-blue {
  animation-name: blink-blue;
  animation-duration: 0.2s;
  animation-timing-function: step-end;
  -webkit-animation-iteration-count: 10; /* Safari 4.0 - 8.0 */
  animation-iteration-count: 10;
  animation-direction: alternate;
}

@keyframes blink-red {
  0% {
    border: 5px solid white;
  }
  50% {
    border: 5px solid red;
  }
  100% {
    border: 5px solid white;
  }
}

.flashing-border-red {
  animation-name: blink-red;
  -webkit-animation: blink-red linear 1s infinite;
  animation: blink-red linear 1s infinite;
  padding-top: 15px;
  // margin: 0;
}

.item-ended {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px #000;
  font-size: 5rem;
  font-weight: bolder;

  .fa {
    transform: rotate(45deg);
  }
}

.action-banner {
  border-top: solid 1px $bg-color2;
  box-shadow: 1px 3px 1px rgba(212, 212, 212, 0.3);
  max-height: 100px;
  margin-right: 0;
  margin-left: 0;
  margin-top: 108px;

  @media (max-width: 1200px) {
    margin-top: 67px;
  }

  @media (max-width: 767px) {
    margin-bottom: 55px;
    box-shadow: none;
    margin-top: 51px;
    padding-top: 15px;
  }

  .vertical-hr {
    margin: 0 15px;
  }

  .logo-button-container {
    display: flex;
    justify-content: stretch;

    @media (max-width: 767px) {
      margin: 0 auto;
      justify-content: center;
      flex-direction: column;
      flex-flow: wrap;

      .vertical-hr {
        display: none;
      }
    }

    img {
      margin: 0 15px;
      order: 1;
    }

    .live-auction-button {
      order: 2;
      font-size: 1.7rem;
      width: 100%;
      max-height: 40px;
      max-width: 150px;
      margin: 6px 6px 6px 0;

      @media (max-width: 480px) {
        width: 80px;
        height: 100%;
        max-height: 22px;
        font-size: inherit;
      }
    }

    .vertical-hr {
      order: 3;
    }

    .date {
      order: 4;
      margin: 15px 0 0;
      font-size: 1.5rem;

      @media (max-width: 1366px) {
        width: 100%;
        max-width: 350px;
      }

      @media (max-width: 576px) {
        margin: 7px 0 0;
        text-align: center;
      }
    }
  }

  .toggle-container {
    position: relative;
    display: flex;
    border: solid 1px $bg-color2;
    max-height: 40px;
    align-content: space-evenly;
    justify-content: center;
    max-width: 230px;
    transform: translate(0, -50%);
    top: 50%;

    @media (max-width: 767px) {
      margin: 0 auto;
      justify-content: center;
      margin-bottom: 15px;
      // border-top: 0;
      // border-bottom: 0;
    }

    .insta-slider-text {
      order: 1;
      margin: 9px 0;
    }

    .insta-toggle {
      order: 2;
      margin: 6px 3px 0;
    }

    .vertical-hr {
      order: 3;
      margin: 0 5px;
    }

    .volumn-btn {
      order: 4;
      margin: 10px auto;

      .fa {
        font-size: 1.2rem;
        margin-right: 4px;
      }
    }
  }
}

.max-bid-container {
  width: 100%;
  padding: 0;

  &.bid {
    margin-top: 8px;
  }

  .bid-header {
    background-color: $primary-color;
    padding: 13px 10px;

    h4 {
      font-size: 1.2rem !important;
      color: $white !important;
      margin-bottom: 0;
    }

    span {
      font-size: 1.2rem !important;
    }
  }

  .insta-bid-container {
    padding: 13px 15px;
    max-height: 100%;

    p {
      float: left;
      font-size: 1rem;
    }

    .react-toggle {
      float: right;
    }

    .fa-info {
      position: relative;
      background-color: $secondary-color;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      color: $white;
      font-size: 0.7rem;

      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .maxBidButton {
      border: 1px solid rgba(212, 212, 212, 0.5);
      display: flex;
      justify-content: space-evenly;
      // margin-top: -12px;
      padding: 3px 0;

      .btn {
        order: 1;
        flex: 0 0 15%;
        border-right: 1px solid rgba(212, 212, 212, 0.5);
        padding: 0;
        background-color: $white;
        color: $black;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
        min-height: auto;

        &:nth-of-type(2) {
          order: 3;
          border-left: 1px solid rgba(212, 212, 212, 0.5);
          border-right: 0;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }

      span {
        flex: 0 0 70%;
        order: 2;
        border: 0;
        font-size: 1.2rem !important;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .confirm-bid-wrapper {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;

    .btn {
      font-size: 1rem;

      &.cancel {
        order: 1;
        border-color: $light-grey-border;
        margin-right: 7.5px;
      }

      &.insta {
        flex: 0 0 71%;
        order: 2;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.auction-tabs {
  margin-top: 15px;
  &.nav-tabs {
    border: 0;

    .nav-link {
      border: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-size: 1.4rem;
      font-family: $bebasNeue;
      opacity: 0.6;

      &.active {
        border: 0;
        border-bottom: 4px solid $secondary-color;
        opacity: 1;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

.auction-pane {
  border-top: 1px solid #dee2e6;

  .row {
    border-bottom: solid 1px #dee2e6;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0;

    &.headers {
      border-bottom: 0 !important;
      color: #000000;
      opacity: 0.6;
      font-weight: bold;
      font-family: $bebasNeue;
      font-size: 1.1rem;
      border-top: 0;

      @media (max-width: 996px) {
        display: none;
      }
    }
  }

  .col {
    justify-content: center;
    align-self: center;

    @media (min-width: 996px) {
      text-align: center;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        text-align: left;
      }
    }

    @media (max-width: 996px) {
      flex: 1 1 50%;
      padding: 0;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(8) {
        flex: 1 1 100%;
      }
    }

    @media (max-width: 767px) {
      flex: 1 1 100%;
    }

    &:nth-of-type(1) {
      padding-left: 0;
    }

    &.categoryHeading {
      color: $black;
      opacity: 1;
      font-size: 1rem;
      background-color: $primary-color;
      color: $white;
      padding: 5px;
      text-transform: uppercase;
    }

    span {
      display: none;

      @media (max-width: 996px) {
        display: block;
        float: left;
        margin-right: 5px;
        font-weight: 900;
        color: #000;
      }
    }
  }

  @media (max-width: 576px) {
    .flexTable {
      .flexTableRow {
        .flexTableData {
          .fa-file-pdf-o {
            display: contents !important;
          }

          a {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.disable-scroll {
  height: 100%;
  overflow: hidden;
}

#countdown {
  width: 100%;
  height: auto;
  text-align: center;
  background: $primary-color;
  /*background-image: -webkit-linear-gradient(top, #222, #333, #333, #222);
    background-image: -moz-linear-gradient(top, #222, #333, #333, #222);
    background-image: -ms-linear-gradient(top, #222, #333, #333, #222);
    background-image: -o-linear-gradient(top, #222, #333, #333, #222);*/
  border: 1px solid #111;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  margin: auto;
  padding: 24px 0;

  h2 {
    font-size: 65px;
    color: #fff;

    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
}

#countdown #tiles {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

#countdown #tiles > span {
  width: 100%;
  font: bold 65px "Droid Sans", Arial, sans-serif;
  text-align: center;
  color: #111;
  background-color: #ddd;
  background-image: -webkit-linear-gradient(top, #bbb, #eee);
  background-image: -moz-linear-gradient(top, #bbb, #eee);
  background-image: -ms-linear-gradient(top, #bbb, #eee);
  background-image: -o-linear-gradient(top, #bbb, #eee);
  border-top: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
  margin: 0 7px;
  padding: 18px 0;

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}

#countdown #tiles > span:before {
  content: "";
  width: 100%;
  height: 13px;
  display: block;
  padding: 0 3px;
  position: absolute;
  top: 41%;
  left: -3px;
  z-index: -1;
}

#countdown #tiles > span:after {
  content: "";
  width: 100%;
  height: 1px;
  border-top: 1px solid #333;
  display: block;
  position: absolute;
  top: 48%;
  left: 0;
}

#countdown .labels {
  display: flex;
  justify-content: space-between;
  text-align: center;
  bottom: 8px;
}

#countdown .labels li {
  width: 100%;
  margin-top: 5px;
  font: bold 15px "Droid Sans", Arial, sans-serif;
  color: #fff;
  text-shadow: 1px 1px 0px #000;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  font-size: 1.3rem;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

.live-auction-container {
  flex: 1;

  @media (max-width: 1400px) {
    max-width: 1240px;
  }
}

.ended {
  @media (max-width: 576px) {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    img {
      max-width: 270px !important;
    }
  }
}
