//fonts

$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$assetspath: "/assets/images";
$bebasNeue: BebasNeue;
$myriadPro-Bold: MyriadPro-Bold;
$myriadReg: MyriadReg;
$bebasBold: BebasBold;

//colors
:root {
  --primary-color: #000000;
  --secondary-color: #2d2a25;
  --text-color1: #000000;
  --text-color2: #333333;
  --text-color3: #666666;
  --text-color4: #ffffff;
  --header-menu-bg-color: #ffffff;
  --header-menu-text-color: #000000;
}

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$text-color1: var(--text-color1);
$text-color2: var(--text-color2);
$text-color3: var(--text-color3);
$text-color4: var(--text-color4);
$header-menu-bg-color: var(--header-menu-bg-color);
$header-menu-text-color: var(--header-menu-text-color);

$white: #ffffff;
$white-dark: #eeeeee;
$black: #000000;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color: #333333;
$grey: #777777;
$grey-light: #a1a1a1;
$border: #dddada;
$round-border: #dddddd;
$grey-lighter: #f9f9f9;
$theme-deafult: #0040ff;
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g: #525252;
$top-header: #f8f8f8;
$header-font: #999999;
$shadow: #ededed;
$box-border: #ecececa8;
$grey-about: #f7f7f7;
$grey2: #555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6: #6f6f6f;
$grey7: #999999;
$sidebar-border: #f5f2f2;
$sidebar-color: #938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$pink: #f1e7e6;
$blue: #bfbfbf;
$icon: #6f6f6f;
$primary: #003c71;
$red: red;
$grey-light: #e3e7ec;
$grey-lighter: #e3e7ec90;
$light-grey-border: #d9dde9;
$light-grey-font: #6c757d;
$light-grey: #f8f8f8;

// $secondary-color:#930000;
// $primary-color: #c70000;
