﻿.mainImage {
    width: 100%;
    object-fit: contain;
    // max-height: 500px;
}

.thumbnailImageContainer {
    margin: 1rem 0;
}

.thumbnailImage {
    height: 100px;
    width: 100%;
    object-fit: fill;
    padding: 2.5px;
}

.imageContainer {
    display: flex !important;
    justify-content: center;
    // background: #000;
}
