

.card {
    &.notification {

        .row {
            border-bottom: solid 1.3px #dee2e6;

            .col-12 {
                padding-top: 10px;
                padding-bottom: 10px;

                p {
                    margin-bottom: 0;
                }
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }

        .card-body {
            padding-bottom: 0;
        }
    }
}

.cardSubHeader{
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
    padding: .25em;
    display: block;
    padding: 3px 5px;
    color: $black;
    border-bottom: solid 1px #d9dde9;
}

.form-check {
    .form-check-input{
        margin-left: 15px;
    }
}