.welcome {
    height: 60vh;

    @media (min-width: 1200px) {
        height: 100vh;
    }

    @media (max-width: 640px) and (max-height: 360px) {
        height: 100vh;
    }

     .center-block {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
     }
}