@import 'theme/_variables.scss';

.compare-container {
    position: relative;
    min-height: 180px;

    .another-container {
        position: relative;
        border: solid 1px $bg-color2;

        @media (max-width: 990px) {
            margin-bottom: 10px;
        }
        

        div {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            @media (max-width: 1200px) {
                position: relative;
            }
        }

        .fa-plus {
            margin: 0 auto 15px auto;
        }
    }

    h3 {
        color: $secondary-color;
    }

    .btn {
        max-height: 37px;
    }

    .compare-item-container {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        border: solid 1px $bg-color2;

        @media (max-width: 991px) {
            border-left: solid 1px $bg-color2;
        }

        .List {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 100%;

            .draggable {
                flex: 0 0 50%;
                cursor: pointer;

                @media (max-width: 996px) {
                    flex: 0 0 100%;
                }

                &.dragged {
                    max-width: 694px;
                    opacity: 1;
                    background-color: $white;
                }
            }

            .placeholder {
                background-color: $light-grey-border;
                opacity: .7;
                border: 2px inset $bg-color2;
                text-align: center;
                justify-content: center;
                height: auto;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $black; 
                }
            }
        }

    .col-12 {
        padding: 5px;

        .compared-items {
            justify-content: space-between;
            display: flex;
            flex-wrap: wrap;
            border: solid 1px $bg-color2;
            padding: 7px;
            transition: 2s all;
            min-height: 173px;

            @media (max-width: 1366px) {
                min-height: 207px;
            }

            @media (max-width: 768px) {
                min-height: auto;
            }
            
            .img-wrapper {
                flex: 1 1 20%;
            }
    
            .item-description {
                flex: 1 1 60%;
                padding: 0 15px;
    
                p {
                    margin-bottom: 2px;
    
                    &:last-of-type {
                        margin-top: 5px;
                    }
                }
            }
    
            .remove-container {
                flex: 1 1 1%;
                display: flex;
                justify-content: center;
                max-height: 34px;
            }
    
            .fa-close {
                border: solid 1px $bg-color2;
                padding: 2px 4px;
                border-radius: 5px;
            }

            .inspection-details-container {
                margin-top: 10px;
                flex: 1 1 100%;

                .uncontrolled-collapse {
                    flex: 1 1 100%;}
                }
    
                .collapse, .collapsing {
                    flex: 1 1 100%;
                }
            }
        }
    }

}